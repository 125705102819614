import { useRoutes } from "react-router-dom";
import Home from '../home/home';
import Equipment from '../equipment';
import EquipmentBoxing from '../equipment/boxing';
import News from '../news';
import NewsOld from '../newsOld';
import Details from '../details';
import Article from '../article';
import About from '../about';
import SmartArticle from '../smartArticle';
import NewsDetail from '../newsDetail';
import Privacy from '../Privacy'

// 创建路由
const routes = [
  {
    path: "/",
    element: (<Home></Home>)
  },
  {
    path: "/equipment",
    element: (<Equipment></Equipment>)
  },
  {
    path: "/equipment-boxing",
    element: (<EquipmentBoxing />)
  },
  {
    path: "/newsold",
    element: (<NewsOld></NewsOld>)
  },
  {
    path: "/newslist",
    element: (<News></News>)
  },
  {
    path: "/newslist",
    element: (<News></News>)
  },
  {
    path: "/news-detail/:newsId",
    element: (<NewsDetail></NewsDetail>)
  },
  {
    path: "/detail",
    element: (<Details></Details>)
  },
  {
    path: "/about",
    element: (<About></About>)
  },
  {
    path: "/article/:articleId",
    element: (<Article></Article>)
  },
  {
    path: "/smartarticle/:articleId",
    element: (<SmartArticle></SmartArticle>)
  },
  {
    path: "/privacy",
    element: (<Privacy />)
  }
];

const useRouter = () => useRoutes(routes);
export default useRouter;