import styles from './title.rem.module.scss';

function Title(props){
    return (
        <div className={styles.homeTitle}>
            <h1>{props.title}</h1>
            <div></div>
        </div>
    )
}

export default Title;
