import styles from './footer.rem.module.scss'
import Icon1 from './images/icon_ins.png'
import Icon2 from './images/icon_x.png'
import Icon3 from './images/icon_fb.png'
import Icon4 from './images/icon_youtubo.png'
import Logo from '../header/images/logo.png'

function Footer() {
  return (
    <div className={styles.footer}>
      <div
        // style={{
        //   display: 'flex',
        //   justifyContent: 'center',
        //   borderTop: '1px solid #EDE2E0',
        //   paddingTop: '40px',
        // }}
      >
        <img
          className={styles.logo}
          src={Logo}
          width={54}
          height={66}
          alt='logo'
        />
      </div>
      {/* <div className={styles.icon}>
          <img src={Icon1} alt='ins' />
          <img src={Icon2} alt='x' />
          <img src={Icon3} alt='fb' />
          <img src={Icon4} alt='youtube' />
        </div> */}
      {/* <h1>Expert Customer Service</h1> */}
      <p className={styles.title}>Email</p>
      <p className={styles.content}>
        <a
          href='mailto:contact@xempowerusa.com'>
          contact@xempowerusa.com
        </a>
      </p>
      {/* <p className={styles.title}>Address</p>
      <p className={styles.content}>
        <span>
          111 S Tejon St Ste 102, Colorado 
        </span>
        <span>
          Springs, CO 80903
        </span>
      </p> */}
      {/* <p>Rechtsanwälte LLP</p>
        <p>Berliner Freiheit 2</p>
        <h2>TEL</h2>
        <p>000-0000 0000</p>
        <h2>E-MAIL</h2>
        <p>000000000@gmial.com</p>
        <h2>C.P.</h2>
        <p>Eden Angus</p> */}
      {/* <footer className={styles.footer}>
        
      </footer> */}
      <div className={styles.copyright}>
        Copyright ©xempowerusa.com.,Ltd. All Rights Reserved.
      </div>
    </div>
  )
}

export default Footer
