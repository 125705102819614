import styles from './title.module.scss';

function Title(props) {
  return (
    <div className={styles.title}>
      {props.title}
    </div>
  );
}

export default Title;