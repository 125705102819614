import './index.scss'
const Privacy = () => {
  return (
    <>
      <div className='privacy'>
        <h3>Last Updated: July 10, 2024</h3>
        <h3>Effective Date: July 10, 2024</h3>
        <div style={{ marginTop: '20px' }}></div>
        <h2>Privacy Policy</h2>
        <p>
          Xempower ("we", "our", "us") is committed to protecting your privacy.
          This Privacy Policy explains how we handle your information when you
          use our Breaking Scoring application ("App").
        </p>
        <p>
          <br />
        </p>
        <h3>Information We Collect</h3>
        <p>
          Our App is designed to be used offline and does not collect, store, or
          share any personal information. We do not require any user
          registration or login to use the App.
        </p>
        <p>
          <br />
        </p>
        <h3>Data Collection and Usage</h3>
        <p>
          <strong>No Data Collection: </strong>The App does not collect any
          personal or non-personal data from users.
        </p>
        <p>
          <strong>No Internet Connection Required:</strong> The App functions
          entirely offline and does not require an internet connection.
        </p>
        <p>
          <strong>No Third-Party Services:</strong> The App does not integrate
          with any third-party services or use any third-party analytics tools.
        </p>
        <p>
          <br />
        </p>
        <h3>User Permissions</h3>
        <p>
          The App does not request any special permissions from users beyond
          those necessary for its basic functionality, which does not involve
          accessing or collecting any personal data.
        </p>
        <p>
          <br />
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. Any changes will
          be posted on this page with an updated revision date.
        </p>
        <p>
          <br />
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at: contact@xempowerusa.com
        </p>
        <p>
          <br />
        </p>
        <p>
          By using our App, you agree to this Privacy Policy. If you do not
          agree with this policy, please do not use our App.
        </p>
      </div>
    </>
  )
}

export default Privacy
