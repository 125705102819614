import video1 from './images/video1.png'
import video2 from './images/video2.png'
import boxingPoster from './images/boxingPoster.png'
import breaking2Poster from './images/breaking-video2.png'
import skateboarding2Poster from './images/skateboarding-video2.png'
import video3Poster from './images/video3.png'
import arc1 from './images/arc1.png'
import arc2 from './images/arc2.png'

import box1 from './images/img_boxing.png'
import box2 from './images/img_breaking_2.png'
import box3 from './images/img_breaking.png'
import box4 from './images/img_marethon.png'

import marathon from './images/marethon.png'
import crossCountry from './images/crossCountry.png'
import breaking from './images/breaking.png'
import skateboard from './images/skateboarding.png'
import carni from './images/canni.png'
import biking from './images/biking.png'
import boating from './images/boating.png'
import boxing from './images/boxing.png'

import marathonDetail from './images/marethonDetail.png'
import crossCountryDetail from './images/crossCountryDetail.png'
import breakingDetail from './images/breakingDetail.png'
import skateboardDetail from './images/skateboardingDetail.png'
import carniDetail from './images/canniDetail.png'
import bikingDetail from './images/bikingDetail.png'
import boatingDetail from './images/boatingDetail.png'
import boxingDetail from './images/boxingDetail.png'

import beakingSystem from './images/breakingSystem.png'
import boxingSystem from './images/boxingSystm.png'
import skateingSystem from './images/skateboardingSystem.png'
import integrateSystem from './images/intergrateSystem.png'
import aiBoxingSystem from './images/aiBoxingSystem.png'

import beakingSystemDetail from './images/breakingSystemDetail.png'
import boxingSystemDetail from './images/boxingSystmDetail.png'
import boxingAbout1 from './images/boxingAbout1.png'
import boxingAbout2 from './images/boxingAbout2.png'
import boxingAbout3 from './images/boxingAbout3.png'
import boxingAbout4 from './images/boxingAbout4.png'
import boxingAbout5 from './images/boxingAbout5.png'
import boxingAbout6 from './images/boxingAbout6.png'
import boxingExpImg from './images/boxingExperience.png'
import breakingAbout1 from './images/breakingAbout1.png'
import breakingAbout2 from './images/breakingAbout2.png'
import breakingAbout3 from './images/breakingAbout3.png'
import breakingAbout4 from './images/breakingAbout4.png'
import breakingExpImg from './images/breakingExperience.png'

import skateingSystemDetail from './images/skateboardingSystemDetail.png'
import integrateSystemDetail from './images/intergrateSystemDetail.png'
import aiBoxingSystemDetail from './images/aiBoxingSystemDetail.jpg'
import skbAbout1 from './images/skbAbout1.png'
import skbAbout2 from './images/skbAbout2.png'
import skbAbout3 from './images/skbAbout3.png'
import skbAbout4 from './images/skbAbout4.png'
import skbAbout5 from './images/skbAbout5.png'
import skbExp from './images/skbExp.png'

import boxingEventNews1 from './images/boxing-event-news1.png'
import boxingEventNews2 from './images/boxing-event-news2.png'
import boxingEventNews3 from './images/boxing-event-news3.png'
import boxingEventNews4 from './images/boxing-event-news4.png'

import breakingEventNews1 from './images/breaking-event-news1.png'
import breakingEventNews2 from './images/breaking-event-news2.png'
import breakingEventNews3 from './images/breaking-event-news3.png'
import breakingEventNews4 from './images/breaking-event-news4.png'

import skateboardingEventNews1 from './images/skateboarding-event-news1.png'
import skateboardingEventNews2 from './images/skateboarding-event-news2.png'

import eomsDetail from './images/eomsDetail.png'
import eoms1 from './images/eoms1.png'
import eoms2 from './images/eoms2.png'
import eoms3 from './images/eoms3.png'
import eoms4 from './images/eoms4.png'
import eventExp from './images/eventExp.png' 
import eomsSystem from './images/eomsSystem.png'

export const album = [
  {
    id: 8,
    parent: 'Event Operation',
    title: 'Boxing',
    desc: 'We are adept at brand packaging and commercial operations, to ensure competitiveness and entertainment of the boxing sport. By leveraging high-quality content IP, we engage in cooperation in various aspects, for example, seeking cooperation with sports celebrities, to establish a complete and mature market-oriented operation system that is more scientific, systematic, and international.',
    img: boxing,
    imgDetail: boxingDetail,
    editor: `
    We are adept at brand packaging and commercial operations, to ensure competitiveness and entertainment of the boxing sport. By leveraging high-quality content IP, we engage in cooperation in various aspects, for example, seeking cooperation with sports celebrities, to establish a complete and mature market-oriented operation system that is more scientific, systematic, and international.
    `,
    eventNews: [
      {
        img: boxingEventNews1,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      },
      {
        img: boxingEventNews2,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      },
      {
        img: boxingEventNews3,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      },
      {
        img: boxingEventNews4,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      }
    ],
    eventVideos: [

    ]
  },
  {
    id: 3,
    parent: 'Event Operation',
    title: 'Breaking',
    desc: 'We host breaking events and provide a wide range of services for those who seek to develop their own events. These services include event registration, streaming services, and merchandising. We provide a stage for break dancers to showcase their skills, promote the exchange and integration of breaking culture, contribute to the professional development of breaking, and enhance its competitive level and influence worldwide.',
    img: breaking,
    imgDetail: breakingDetail,
    editor: `
    We host breaking events and provide a wide range of services for those who seek to develop their own events. These services include event registration, streaming services, and merchandising. We provide a stage for break dancers to showcase their skills, promote the exchange and integration of breaking culture, contribute to the professional development of breaking, and enhance its competitive level and influence worldwide.
    `,
    eventNews: [
      {
        img: breakingEventNews1,
        title: 'breaking Boxing Boxing',
        time: '2023.11.03~11.04'
      },
      {
        img: breakingEventNews2,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      },
      {
        img: breakingEventNews3,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      },
      {
        img: breakingEventNews4,
        title: 'Boxing Boxing Boxing',
        time: '2023.11.03~11.04'
      }
    ],
    eventVideos: [
      {
        poster: video2,
        contro: false,
        id: 1,
        src: 'https://www.xempowerusa.com/videos/breaking.MP4',
      },
      {
        poster: breaking2Poster,
        contro: false,
        id: 2,
        src: 'https://www.xempowerusa.com/videos/break2.m4v',
      },
    ]
  },
  {
    id: 4,
    parent: 'Event Operation',
    title: 'Skateboarding',
    desc: 'Skateboarding was recognized by the IOC as an official Olympic sport in 2016. Skateboarding in its most competitive form consists of two events: Street and Park. We host skating events and provide services such as: media operations, support event operations, talent management, big data processing, intelligent device implementation, and independent sports brand development. ',
    img: skateboard,
    imgDetail: skateboardDetail,
    editor: `
    Skateboarding was recognized by the IOC as an official Olympic sport in 2016. Skateboarding in its most competitive form consists of two events: Street and Park. We host skating events and provide services such as: media operations, support event operations, talent management, big data processing, intelligent device implementation, and independent sports brand development. 
    `,
    eventNews: [
      {
        img: skateboardingEventNews2,
        title: '',
        time: ''
      },
      {
        img: skateboardingEventNews1, 
        title: '',
        time: ''
      }
    ],
    eventVideos: [
      {
        poster: video1,
        id: 1,
        contro: false,
        src: 'https://www.xempowerusa.com/videos/skateboarding.MP4',
      },
      {
        poster: skateboarding2Poster,
        id: 2,
        contro: false,
        src: 'https://www.xempowerusa.com/videos/20240111.MP4'
      },
    ]
  },
  {
    id: 1,
    parent: 'Event Operation',
    title: 'Marathon',
    onlyOnePic: true,
    desc: 'We organize high-quality marathon events and fun runs, to create an immersive and inclusive running experience for all. We provide participants with a world-class marathon experience and cities with worldwide visibility. ',
    img: marathon,
    imgDetail: marathonDetail,
    editor: `
    We organize high-quality marathon events and fun runs, to create an immersive and inclusive running experience for all. We provide participants with a world-class marathon experience and cities with worldwide visibility. 
    `,
  },
  {
    id: 2,
    parent: 'Event Operation',
    title: 'Trail Running',
    onlyOnePic: true,
    desc: 'In hosting trail running events we aim to maximize the participant experiences and provide visibility to the host location. By combining sports and tourism our events showcase the host place in its totality, from its hotels and services to its natural landscapes. ',
    img: crossCountry,
    imgDetail: crossCountryDetail,
    editor: `
    In hosting trail running events we aim to maximize the participant experiences and provide visibility to the host location. By combining sports and tourism our events showcase the host place in its totality, from its hotels and services to its natural landscapes. 
    `,
  },
  {
    id: 5,
    parent: 'Event Operation',
    title: 'Carnival',
    desc: 'By combining elements such as music, food, art, and designer toys, we provide a one-stop shopping and leisure experience that integrates commerce, culture, art, tourism, and sports. Our professional team provides professional services and develops high-quality content. We build unique carnivals and expos in cities, providing a new paradigm of sports and entertainment, both online and offline. We seek to boost business, promote culture, enhance tourism, and build city brands and businesses by providing an opportunity for consumption, promoting the integration of culture and tourism, and stimulating urban vitality.',
    img: carni,
    imgDetail: carniDetail,
    editor: `
    By combining elements such as music, food, art, and designer toys, we provide a one-stop shopping and leisure experience that integrates commerce, culture, art, tourism, and sports. Our professional team provides professional services and develops high-quality content. We build unique carnivals and expos in cities, providing a new paradigm of sports and entertainment, both online and offline. We seek to boost business, promote culture, enhance tourism, and build city brands and businesses by providing an opportunity for consumption, promoting the integration of culture and tourism, and stimulating urban vitality.
    `,
  },
  {
    id: 6,
    parent: 'Event Operation',
    title: 'Cycling',
    desc: 'We provide information on cycling events, product details, industry updates, equipment reviews and cycling routes, as well as offer one-stop event registration for cycling enthusiasts. Through professional channels both online and offline, we extend the value of cycling and form an integrated development model of "one-day competition, multi-day stay, and multi-person tourism". We promote fitness by launching family fun rides and youth-oriented cycling competitions. Adopting an "events + industry" approach, we intend to build a platform for cycling exchanges, and facilitate resources integration and collaboration among multiple parties.',
    img: biking,
    imgDetail: bikingDetail,
    editor: `
    We provide information on cycling events, product details, industry updates, equipment reviews and cycling routes, as well as offer one-stop event registration for cycling enthusiasts. Through professional channels both online and offline, we extend the value of cycling and form an integrated development model of "one-day competition, multi-day stay, and multi-person tourism". We promote fitness by launching family fun rides and youth-oriented cycling competitions. Adopting an "events + industry" approach, we intend to build a platform for cycling exchanges, and facilitate resources integration and collaboration among multiple parties.
    `,
  },
  {
    id: 7,
    parent: 'Event Operation',
    title: 'Rowing',
    desc: 'With professional sports facilities and rowing as the core, we focus on professional event operations to create a stage for athletes to compete and a platform for rowing enthusiasts to share the fun of this sport. Meanwhile, we aim to promote and popularize the sport of rowing. ',
    img: boating,
    imgDetail: boatingDetail,
    editor: `
    With professional sports facilities and rowing as the core, we focus on professional event operations to create a stage for athletes to compete and a platform for rowing enthusiasts to share the fun of this sport. Meanwhile, we aim to promote and popularize the sport of rowing.  
    `,
  },
  
]

export const smart = [
  {
    id: 10,
    isBoxing: true,
    parent: 'Event Systems',
    title: 'AI Boxing Scoring System',
    secTitle: 'Efficient management empowers event organization',
    desc: 'Al Boxing Scoring System is a professional scoring and analyzing platform supported by AI technology. With a high-definition and high-speed camera, it can capture boxers',
    img: boxingSystem,
    imgDetail: boxingSystemDetail,
    editor: `
      <p>
      Al Boxing Scoring System is a professional scoring and analyzing platform supported by AI technology. With a high-definition and high-speed camera, it can capture boxers' actions in competitions and training sessions, count in real time the number of punches thrown by both sides, the number of effective hits, hit parts, as well as the movement trajectory, distance, striking power, and other data, and make a statistical analysis and judgment, assisting referees to quickly analyze and review the game without interfering with their judgment.
     </p>
    `,
    eventVideos: [
      {
        poster: boxingPoster,
        contro: false,
        id: 'boxing1',
        src: 'https://www.xempowerusa.com/videos/aipbss.m4v',
      },
    ],
    aboutContent: `
      <p>
      The system consists of AI boxing scoring software, smart boxing gloves, an AI visual analysis unit, a referee scoring keyboard, and a display module, meeting the demands of both daily training and professional events. During training, the system can help coaches get an objective understanding of boxers' techniques and tactics and optimize their training plans. During matches, the system can assist referees in their work and even make independent refereeing. In addition, boasting a powerful statistical and analytical capability, the system can collect and analyze the boxing data, form a visual data board and correlate with the match video, which helps professionals to study match strategies in depth and interpret key moments.
      </p>
    `,
    aboutImgs: [
      {
        id: 1,
        img: boxingAbout1
      },
      {
        id: 2,
        img: boxingAbout2
      },
      {
        id: 3,
        img: boxingAbout3
      },
      {
        id: 4,
        img: boxingAbout4
      },
      {
        id: 5,
        img: boxingAbout5
      },
      {
        id: 6,
        img: boxingAbout6
      }
    ],
    expImg: eventExp

  },
  {
    id: 12,
    isEoms: true,
    skipped: false,
    parent: 'Event Systems',
    title: 'Event Operation Management System',
    secTitle: 'Enabling efficient and stable operation',
    desc: 'The Boxing Event Management System is an all-in-one solution provided by xempower for boxing events. It is designed for the efficient organization and management of boxing events and aims to provide boxing event organizers with a comprehensive and efficient management tool to improve the overall experience of the events.',
    img: eomsSystem,
    imgDetail: eomsDetail,
    editor: `
      <p>
      The Boxing Event Management System is an all-in-one solution provided by xempower for boxing events. It is designed for the efficient organization and management of boxing events and aims to provide boxing event organizers with a comprehensive and efficient management tool to improve the overall experience of the events.
     </p>
    `,
    aboutContent: `
      <p>
      The system supports the creation of events. From player sign-up to the entire process of events, everything can be managed on the platform. The player management feature covers the entry and update of player information, as well as player roll call and sign-up information, which helps ensure the accuracy of their information. The event official management module makes it easier for event organizers to arrange and allocate referees, which provides guarantees for fair scoring. Also, the EOMS system supports player drawing and automatically generates matchups, which eliminates human operational errors. The event scheduling feature makes the event process smoother, which effectively reduces schedule conflicts. In addition, the referee scoring module is integrated into the system and supports real-time scoring and data recording, which ensures the fairness and transparency of the competition results.
      </p>
    `,
    aboutImgs: [
      {
        id: 1,
        img: eoms1
      },
      {
        id: 2,
        img: eoms2
      },
      {
        id: 3,
        img: eoms3
      },
      {
        id: 4,
        img: eoms4
      },
      
    ],
    expImg: eventExp

  },
  {
    id: 9,
    isBreaking: true,
    parent: 'Event Systems',
    title: 'Breaking Scoring System',
    secTitle: 'Accurate judgment inspires real dance',
    desc: 'The Imohoo Breaking Judging System is utilized in high-level competitions, including World and Continental Championships, Qualifiers to Games of the Continental Olympic Associations/Committees or the IOC',
    img: beakingSystem,
    imgDetail: beakingSystemDetail,
    editor: `
      <p>
        The Imohoo Breaking Judging System is utilized in high-level competitions, including World and Continental Championships, Qualifiers to Games of the Continental Olympic Associations/Committees or the IOC, Continental Games and BfGWS events and other high level events.
      </p>
      <p>
        On the premise of not interfering with the judging results of Judges, the process and results of important games can be quickly analyzed and counted.
      </p>
    `,
    functions: [
      'The event system supports basic functions such as game management, registration, arrangement, cypher division, process control, etc.',
      'Statistics and management of personnel information (arbiters, head judge, judges, athletes, etc.)',
      'Provide a scoring system for events that meets WDSF technical standards',
      'Produce groups, matches, scores and other data displays for live and TV broadcasts',
      'The score processing system supports query of schedule, score, scoring details, error correction, etc.',
    ],
    aboutImgs: [
      {
        id: 1,
        img: breakingAbout1
      },
      {
        id: 2,
        img: breakingAbout2
      },
      {
        id: 3,
        img: breakingAbout3
      },
      {
        id: 4,
        img: breakingAbout4
      },
      
    ],
    expImg: breakingExpImg
  },
  {
    id: 11,
    parent: 'Event Systems',
    // isNormal: true,
    isSkb: true,
    title: 'Skateboarding Scoring System',
    secTitle: 'A reliable partner for skateboarding competitions',
    desc: 'It is an integrated management system specifically designed for skateboarding competitions and covers five core functions, including event management, competition draw, timing and scoring, result display,',
    img: skateingSystem,
    imgDetail: skateingSystemDetail,
    editor: `
      <p>it is an integrated management system specifically designed for skateboarding competitions and covers five core functions, including event management, competition draw, timing and scoring, result display, and live streaming.</p>
      <p>The system enables flexible configuration in competition formats, scoring and timing rules, to accurately meet requirements for various competitions.</p>
      <p>The system boasts a powerful database that can store data of skaters, referees and competitions. Through data analysis, we can learn about skaters' technical characteristics, referees' scoring habits, and the overall performance of competitions, providing useful reference information for event organizers and skaters.</p>
    `,
    functions: [
     
    ],
    aboutImgs: [
      {
        id: 1,
        img: skbAbout1
      },
      {
        id: 2,
        img: skbAbout2
      },
      {
        id: 3,
        img: skbAbout3
      },
      {
        id: 4,
        img: skbAbout4
      },
      {
        id: 5,
        img: skbAbout5
      },
      
    ],
    expImg: skbExp
  },
  // {
  //   id: 12,
  //   parent: 'Event Systems',
  //   title: 'Smart Event Integrated Service Platform',
  //   secTitle: 'Leading Sci-Tech enables efficient operations',
  //   desc: 'The Smart Event Integrated Service Platform is an all-round, intelligent, and efficient platform that can offer solid support for efficient event operations, ',
  //   img: integrateSystem,
  //   imgDetail: integrateSystemDetail,
  //   editor: `
  //     <p>The Smart Event Integrated Service Platform is an all-round, intelligent, and efficient platform that can offer solid support for efficient event operations, decision optimization, and participant safety, providing a better participation experience for all participants.</p>
  //     <p>The platform consists of multiple sectors including participation security, event management, and coordinated operations. During the competition, by integrating modular data and information, and incorporating such technologies as big data analysis, facial recognition and GPS tracking, seamless integration with the organizing committee's operations system is achieved, thus enabling real-time visual monitoring and management throughout the whole process.</p>
  //   `,
  // },
  // {
  //   id: 13,
  //   parent: 'Event Systems',
  //   title: 'AI Boxing Scoring System',
  //   secTitle: 'AI-based judgement supports boxing competitions',
  //   desc: 'Al Boxing Scoring System is a professional scoring and analyzing platform supported by AI technology.',
  //   img: aiBoxingSystem,
  //   imgDetail: aiBoxingSystemDetail,
  //   editor: `
  //     <p>Al Boxing Scoring System is a professional scoring and analyzing platform supported by AI technology. With a high-definition and high-speed camera, it can capture boxers' actions in competitions and training sessions, count in real time the number of punches thrown by both sides, the number of effective hits, hit parts, as well as the movement trajectory, distance, striking power, and other data, and make a statistical analysis and judgment, assisting referees to quickly analyze and review the game without interfering with their judgment.</p>
  //     <p>The system consists of AI boxing scoring software, smart boxing gloves, an AI visual analysis unit, a referee scoring keyboard, and a display module, meeting the demands of both daily training and professional events. During training, the system can help coaches get an objective understanding of boxers' techniques and tactics and optimize their training plans. During matches, the system can assist referees in their work and even make independent refereeing. In addition, boasting a powerful statistical and analytical capability, the system can collect and analyze the boxing data, form a visual data board and correlate with the match video, which helps professionals to study match strategies in depth and interpret key moments.</p>
  //   `,
  // },
]

export const video = [
  {
    poster: video1,
    id: 1,
    contro: false,
    src: 'https://www.xempowerusa.com/videos/skateboarding.MP4',
  },
  {
    poster: video2,
    contro: false,
    id: 2,
    src: 'https://www.xempowerusa.com/videos/breaking.MP4',
  },
]

export const video3 = [
  {
    poster: video1,
    id: 3,
    contro: false,
    src: '',
  },
  {
    poster: video2,
    contro: false,
    id: 4,
    src: 'https://www.apple.com/105/media/us/mac/family/2023/1b2bbf5c-ddc5-44a1-9dfb-7a51c49143fa/anim/welcome/xlarge.mp4',
  },
]
