import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater'
import styles from './boxing.rem.module.scss'
import { Fragment, useEffect } from 'react'

import equip1 from './images/boxing-equip-1.png'
import equip2 from './images/boxing-equip-2.png'
import equip3 from './images/boxing-equip-3.png'
import equip4 from './images/boxing-equip-5.png'
import equip6 from './images/boxing-equip-6.png'
import equip7 from './images/boxing-equip-7.png'
import equip8 from './images/boxing-equip-8.png'
import icon1 from './images/boxing-icon-1.png'
import icon2 from './images/boxing-icon-2.png'
import icon3 from './images/boxing-icon-3.png'
import icon4 from './images/boxing-icon-4.png'
import icon5 from './images/boxing-icon-5.png'
import media1 from './images/img_quipment_1@2x.png'
import media2 from './images/img_quipment_2@2x.png'
import media3 from './images/img_quipment_3@2x.png'

function Home() {
  const _path = [
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'AI-Powered Punching Bag',
      href: '',
    },
  ]

  useEffect(() => {
    document.body.scrollTop = 0
  }, [])
  return (
    <Fragment>
      <Header></Header>
      <Equipment title='boxing' path={_path} />
      <main className={styles.main}>
        <div className={styles.pageTitle}>AI-Powered Punching Bag</div>
        <div className={styles.title} style={{marginTop: '24px', padding: 0}}>The Punching bag with AI</div>
        <img
          style={{ margin: '0 auto', marginBottom: '16px' }}
          width={343}
          height={330}
          src={equip1}
          alt=''
        />
        <div className={styles.contentWrap}>
          <div className={styles.contentItem}>
            <b>AI-powered coaching:</b> Real-time feedback from sensors for
            every punch
          </div>
          <div className={styles.contentItem}>
            <b>Performance data</b> tracking and analytics
          </div>
          <div className={styles.contentItem}>
            <b>Technique and hit zone</b> instant identification
          </div>
          <div className={styles.contentItem}>
            All-in-one metrics:{' '}
            <b> Speed, power, quantity, accuracy tracking</b>
          </div>
          <div className={styles.contentItem}>
            <b>Deep learning algorithm</b>
          </div>
          <div className={styles.contentItem}>
            Adaptive <b>personalized and guidance</b> training mode
          </div>
          <div className={styles.contentItem}>
            <b>AI-enhanced App</b> connectivity
            {/* <br /> */}
            <p style={{ height: '20px', lineHeight: '20px', fontSize: '18px' }}>
              (under development)
            </p>
          </div>
        </div>
        <img
          style={{ margin: '46px auto 32px auto' }}
          src={equip2}
          width={343}
          height={420}
          alt=''
        />
        <div className={styles.factors}>
          <div className={styles.factor}>
            <img width={48} height={48} src={icon1} alt='' />
            <p>AI processing unit </p>
            <p>and sensors</p>
          </div>
          <div className={styles.factor}>
            <img width={48} height={48} src={icon4} alt='' />
            <p>Integrated surround </p>
            <p>sound</p>
          </div>
          <div className={styles.factor}>
            <img width={48} height={48} src={icon2} alt='' />
            <p>Interactive training</p>
            <p>light spot</p>
          </div>
          <div className={styles.factor}>
            <img width={48} height={48} src={icon5} alt='' />
            <p>Durable High-tech</p>
            <p>surface materials</p>
          </div>
          <div className={styles.factor}>
            <img width={48} height={48} src={icon3} alt='' />
            <p>water core</p>
          </div>
        </div>
        <div className={styles.title}>Interactive between boxers and AI</div>
        <div className={styles.mainContent}>
          <div className={styles.subTitle}>AI Deep Learning Algorithms</div>
          <p className={styles.showBorder}>
            By incorporating training data and utilizing deep learning
            algorithms, a personalized training model can be created.
          </p>
          <div style={{margin: '30px 0 20px 0'}} className={styles.subTitle}>Sensors</div>
          <img style={{margin: '0 auto'}} width={343} height={213} src={equip3} alt='' />
          <p className={styles.showBorder}>
            Capture every punch with precision sensors, charting strike zones
            and combo patterns unique to each boxer.
          </p>
          <div className={`${styles.aiPowered} ${styles.showBorder}`} style={{ }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img src={equip6} alt='' width={174} height={75} />
              <img src={equip7} alt='' width={174} height={235} />
            </div>
            <img width={146} height={328} src={equip4} alt='' />
          </div>

          <div style={{margin: '32px 0 20px 0'}} className={styles.subTitle}>AI-powered boxing tutorials</div>

          <p style={{margin: '0', padding: '0 16px'}} >
            Analytics and feedback on professional boxing videos at different
            technical levels.
          </p>
          <p style={{margin: '10px 0', padding: '0 16px'}}>
            By synchronizing the videos with the LED light spots embedded in the
            surface of the punching bag, interactive guidance can be provided.
          </p>
          <p style={{ padding: '0 16px', paddingRight: '130px'}}>
          Enabling boxers to master the complex offensive maneuvers of elite competitors.
          </p>

          <img
            style={{ position: 'absolute', right: '16px', bottom: '33px' }}
            width={95}
            height={72}
            src={equip8}
            alt=''
          />
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  )
}

export default Home
