import styles from './footer.module.scss';
import Icon1 from './images/icon_ins.png';
import Icon2 from './images/icon_x.png';
import Icon3 from './images/icon_fb.png';
import Icon4 from './images/icon_youtubo.png';
import Logo from '../header/images/img_top_logo@2x.png';

function Footer() {
  return (
    <footer className={styles.footer}>
      <div>
        <div className={styles.icon}>
          <img className={styles.logo} src={Logo} width={66} height={66} alt='logo' />
          {/* <div className={styles.share}>
            <img src={Icon1} alt='ins' />
            <img src={Icon2} alt='x' />
            <img src={Icon3} alt='fb' />
            <img src={Icon4} alt='youtube' />
          </div> */}
        </div>
        {/* <h2>Expert Customer Service</h2> */}
        <table cellPadding={0} cellSpacing={0}>
          <thead></thead>
          <tbody>
            <tr>
            <td width={80}>Email</td>
              <td> <a style={{color: '#411108'}} href="mailto:contact@xempowerusa.com">contact@xempowerusa.com</a> </td>
            </tr>
            {/* <tr>
              <td width={80}>Address</td>
              <td width={423}> 111 S Tejon St Ste 102, Colorado Springs, CO 80903</td>
            </tr> */}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
             
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.copyright}>Copyright © xempowerusa.com.,Ltd. All Rights Reserved.</div>
    </footer>
  );
}

export default Footer;