import styles from "./CardList.module.scss";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import Left from "../../home/images/icon_left.png";
import Right from "../../home/images/icon_right.png";
import Card from "./Card.js";
function CardList(props) {
  const [list, setList] = useState(props.list);
  const [scrollX, setScrollX] = useState(0);

  const [num, setNum] = useState(0);

  const itemWidth = 515;

  const moveLeft = () => {
    if (num < props.num) {
      setScrollX(scrollX - itemWidth * 3);
      setNum(num + 1);
    }
  };

  const moveRight = () => {
    if (scrollX !== 0) {
      setNum(num - 1);
      setScrollX(scrollX + itemWidth * 3);
    }
  };

  const createAlbum = () => {
    const boxWidth = list.length * 539;
    return (
      <div className={styles.album}>
        <div className={styles.swiper} style={{ width: `${boxWidth}px` }}>
          <div
            className={styles.scroll}
            style={{ transform: `translateX(${scrollX}px)` }}
          >
            {/* {list.map((item, index) => (
              <div key={index} className={styles.swiperItem}>
                <NavLink to={item.to}>
                <div
                  className={styles.swiperItemImg}
                  style={{ background: `url('${item.img}') no-repeat` }}
                ></div>
                </NavLink>
                
                <NavLink
                  className={`${
                    props.row === 2
                      ? styles.swiperItemTitleRow
                      : styles.swiperItemTitle
                  }`}
                  to={item.to}
                >
                  {item.title}
                </NavLink>
                <NavLink className={styles.swiperItemDesc} to={item.to}>
                  <span className={styles.brief}>{item.desc}</span>
                </NavLink>
              </div>
            ))} */}
            {list.map((item, index) => (
              <Card item={item} index={index} key={index} />
            ))}
          </div>
        </div>
        {props.showMore && (
          <div className={styles.action}>
            <div>
              <img
                src={Left}
                className={`${scrollX === 0 ? styles.dis : ""}`}
                alt='left'
                onClick={moveRight}
              />
            </div>
            <div>
              <img
                src={Right}
                className={`${
                  num >= ~~(list.length / 3) - 1 ? styles.dis : ""
                }`}
                alt='right'
                onClick={moveLeft}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.card} ${props.className}`}>{createAlbum()}</div>
  );
}

export default CardList;
