import styles from './index.rem.module.scss';

function Detail(props) {
  return (
    <div className={styles.detail}>
      <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online November 2023  Corner Now Online</p>
      <p className={styles.smallTitle}>Nov 21, 2023, 10:32 A.M.</p>
      <p className={styles.tipBox}><span className={styles.tip}>Rowing</span></p>
      <div className={styles.share}>
        <span>Share</span>
        <img src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/icon_ins.png' alt='ins' />
        <img src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/icon_x.png' alt='x' />
        <img src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/icon_fb.png' alt='fb' />
        <img src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/icon_youtubo.png' alt='youtube' />
      </div>
      <p className={styles.info}>
        Marathon is a sport that is characterized by broad participation, great influence, and a long industry chain. It plays an increasingly important role in promoting further integration of national fitness and public health. With professional organization, thoughtful event services, organic integration with local culture, and involvement in public welfare and local communities, we focus on holding immersive, highly collaborative, and in-depth events. Our goals are to promote the branding and normalization of marathon races. We aim to organize high-quality marathon events and fun runs, to create an immersive and inclusive running experience for all. With professional event services, we hope to provide runners with a charming and spectacular race that reaches to the high standard of international-level events.
      </p>
      <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
    </div>
  );
}

export default Detail;