import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater';
import styles from './index.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { smart } from '../home/data';
import Play from '../home/images/btn_play@2x.png'

function CreateVideo({list, setList}) {
  // const [list, setList] = useState([])
  const playVideo = (index, id) => {
    list[index].contro = true
    setList(list.map(item => item))
    document.getElementById('video' + id).play()
  }
  return (
    <div className={styles.videoBox}>
      {list.map((item, index) => (
        <div key={index}>
          {!item.contro && (
            <img
              className={styles.play}
              src={Play}
              alt='play'
              onClick={() => playVideo(index, item.id)}
            />
          )}
          {!item.contro && (
            <img className={styles.poster} src={item.poster} alt='poster' />
          )}
          <video
            id={'video' + item.id}
            controls
            role='img'
            src={item.src}
          ></video>
        </div>
      ))}
    </div>
  )
}

function Article() {

  const params = useParams();

  const [articleInfo, setArticleInfo] = useState({});
  const [videoList, setVideoList] = useState([])

  const [_path, setPath] = useState([])

  const getContent = (articleId) => {
    const article = smart.filter(item => item.id == articleId)[0];
    setPath([{
      title: 'Home',
      href : '/'
    },{
      title: article.title,
      href : ''
    }])
    setArticleInfo(article);

    if (article.eventVideos) {
      setVideoList(article.eventVideos)
    } else {
      setVideoList([])
    }
  }

  useEffect(() => {
    
    if(params.articleId){
      getContent(params.articleId);
    }
  }, [params]);

  return (
    <Fragment>
      <Header></Header>
      <Equipment title={articleInfo.parent} path={_path} />
      {
        articleInfo.isBoxing && articleInfo.eventVideos.length && <>
          <div style={{ width: '100%', height: '550px', background: '#000', display: 'flex', 'justifyContent': 'center' }}>
            <CreateVideo list={videoList} setList={setVideoList} />
          </div>
          <main className={styles.article}>
          <div className={styles.smartTitle}>
          <p>{articleInfo.title}</p>
          {articleInfo.secTitle && <p className={styles.secTitle}>{articleInfo.secTitle}</p>}
        </div>
          <div className={styles.introduce}>
            <img
              className={styles.mainImg}
              width={836}
              height={380}
              src={articleInfo.imgDetail}
              alt=''
            />

            <div
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
            </div>
            <div className={styles.aboutTitle}>
              About The System
            </div>
            <div
              className={styles.aboutContent}
              dangerouslySetInnerHTML={{
                __html: articleInfo.aboutContent,
              }}
            >

            </div>
            <div className={styles.aboutImg}>
              {
                articleInfo.aboutImgs.map((item, index) => {
                  return <img key={index} alt="" src={item.img} width={680} height={381} />
                })
            }
            </div>
            <div className={styles.aboutTitle} style={{marginTop: '72px', marginBottom: '55px'}}>
              Event Experiences
            </div>
            <div >
              <img width={1400} height={452} src={ articleInfo.expImg } alt=" " />
            </div>
          </main>
        </>
      }
      {
        (articleInfo.isBreaking || articleInfo.isSkb) && <>
          <main className={styles.article}>
            <div className={styles.smartTitle}>
              <p>{articleInfo.title}</p>
                {articleInfo.secTitle && <p className={styles.secTitle}>{articleInfo.secTitle}</p>}
            </div>
            <div className={styles.introduce}>
              <img
                className={styles.mainImg}
                width={articleInfo.isSkb ? 688 : 836}
                height={380}
                src={articleInfo.imgDetail}
                alt=''
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: articleInfo.editor,
                }}
              ></div>
            </div>
            <div className={styles.aboutTitle}>
              Functions
            </div>
            {
              articleInfo.functions && articleInfo.functions.length> 0 && <div style={{marginBottom: '32px'}}>
                {
                  articleInfo.functions.map((item, index) => {
                    return <p className={styles.function} key={index}>{ item }</p>
                   
                  })
                }
              </div>
            }
            <div className={styles.aboutImg}>
              {
                articleInfo.aboutImgs.map((item, index) => {
                  return <img key={index} alt="" src={item.img} width={680} height={381} />
                })
            }
            </div>
            <div className={styles.aboutTitle} style={{marginTop: '72px', marginBottom: '55px'}}>
              Event Experiences
            </div>
            <div >
              <img width={1400} height={articleInfo.isSkb ? 245 : 748} src={ articleInfo.expImg } alt=" " />
            </div>
          </main>
        </>
      }
      {
        articleInfo.isEoms && <>
          <main className={styles.article}>
            <div className={styles.smartTitle}>
              <p>{articleInfo.title}</p>
                {articleInfo.secTitle && <p className={styles.secTitle}>{articleInfo.secTitle}</p>}
            </div>
            <div className={styles.introduce} style={{flexDirection:'column'}}>
              <img
                className={styles.mainImg}
                width={1400}
                height={600}
                src={articleInfo.imgDetail}
                alt=''
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: articleInfo.editor,
                }}
              ></div>
            </div>
            <div className={styles.aboutTitle}>
              Main features of the EOMS
            </div>
            <div
            dangerouslySetInnerHTML={{
              __html: articleInfo.aboutContent,
            }}
            >

            </div>
            {
              articleInfo.functions && articleInfo.functions.length> 0 && <div style={{marginBottom: '32px'}}>
                {
                  articleInfo.functions.map((item, index) => {
                    return <p className={styles.function} key={index}>{ item }</p>
                   
                  })
                }
              </div>
            }
            <div className={styles.aboutImg}>
              {
                articleInfo.aboutImgs.map((item, index) => {
                  return <img key={index} alt="" src={item.img} width={680} height={381} />
                })
            }
            </div>
            <div className={styles.aboutTitle} style={{marginTop: '72px', marginBottom: '55px'}}>
              Event Experiences
            </div>
            <div >
              <img width={1400} height={452} src={ articleInfo.expImg } alt=" " />
            </div>
          </main>
        </>
      }
      {
        articleInfo.isNormal && <main className={styles.article}>
        <div className={styles.smartTitle}>
          <p>{articleInfo.title}</p>
          {articleInfo.secTitle && <p className={styles.secTitle}>{articleInfo.secTitle}</p>}
        </div>
        <div dangerouslySetInnerHTML={{
          __html: articleInfo.editor
        }}>
        </div>
        <div>
          <img src={articleInfo.imgDetail} alt="" />
        </div>
        </main>
      }
      
      
      <Footer></Footer>
    </Fragment>
  );
}

export default Article;