// import VConsole from 'vconsole'
import ReactDOM from 'react-dom/client';
import * as React from 'react'
// import reportWebVitals from './reportWebVitals';
import App from './sports-en/layout';
import MobileApp from './sports-en-m/layout';
import { BrowserView, MobileView, isBrowser, isTablet, isMobile} from 'react-device-detect';
import './flexible'
// import 'lib-flexible';




console.log(isBrowser, isTablet, isMobile)
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <BrowserView>
      <App />
    </BrowserView>
    <MobileView>
      <MobileApp />
    </MobileView>
  </React.StrictMode>
);