import styles from './title.module.scss';

function Title(props) {
  return (
    <div className={styles.title}>
      <img src={props.bg} alt='bg' />
      <span>{props.title}</span>
    </div>
  );
}

export default Title;