import RouterConfig from './router'
import { BrowserRouter } from "react-router-dom";
import styles from './layout.rem.module.scss'

import './reset.css';
function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </div>
  );
}

export default App;