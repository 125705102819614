import Title from './title.js'
import Arrow from '../../components/header/icon_top_arrow.js'
import styles from './CardList.rem.module.scss'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import Card from './Card.js'
import { isTablet } from 'react-device-detect';
function CardList(props) {
  const [showAll, setShowAll] = useState(false)

  const changeState = () => setShowAll(!showAll)

  const [list, setList] = useState(props.list)

  const createAlbum = () => {
    return (
      <>
        <ul
          className={`${
            showAll ? `${styles.album} ${styles.albumShowAll}` : styles.album
            } ${isTablet ? styles.tabletAlbum : ''}`}
            
        >
          {list.map((item, index) => {
            return (
              <Card item={item} key={index}/>
            )
          })}
        </ul>
        {!showAll && props.hasMore && (
          <div className={styles.loadMore} onClick={changeState}>
            View all
            <Arrow color='#6D4C47' />
          </div>
        )}
      </>
    )
  }

  return (
    <div className={`${styles.card} ${props.className}`}>
      { props.title ? <Title title={props.title} /> : ''}
      {createAlbum()}
    </div>
  )
}

export default CardList
