import styles from "./header.module.scss";
import Arrow from "./icon_top_arrow";
import { NavLink, useLocation } from "react-router-dom";
import Search from "./images/icon_search.png";
import Logo from "./images/img_top_logo@2x.png";
import { useState, useRef } from "react";
import { album, smart } from "../../home/data";

function Header() {
  const { pathname } = useLocation();

  const [active, setActive] = useState(null);

  const sportsProgrammesSecList = album.map((item) => {
    return Object.assign(item, {
      to: "/article/" + item.id,
    });
  });

  const smartSecList = smart.map((item) => {
    return Object.assign(item, {
      to: "/smartarticle/" + item.id,
    });
  });

  const equipmentList = [
    {
      id: 2,
      parent: "equipment",
      title: "AI-Powered Punching Bag",
      to: "/equipment-boxing",
    },
    {
      id: 1,
      parent: "equipment",
      title: "Skateboarding",
      to: "/equipment",
    },
  ];

  const menu = [
    {
      title: "Home",
      id: 0,
      to: "/",
      activeKey: "/",
    },
    {
      title: "Event Operation",
      activeKey: "/sportsprogrammes",
      width: 230,
      id: 1,
      child: sportsProgrammesSecList,
    },
    {
      title: "Event Systems",
      activeKey: "/sportsprogrammes",
      width: 400,
      id: 2,
      child: smartSecList,
    },
    {
      title: "Equipment Solutions",
      activeKey: "equipment",
      child: equipmentList,
      width: 260,
      // to: '/equipment',
      id: 3,
    },
    {
      title: "News",
      to: "/newslist",
      id: 4,
    },
  ];

  const jumpToPage = (id) => {
    // useLocation.jumpToPage('sportsprogrammes')
    // _navi('sportsprogrammes?id=32');
  };

  const menuRef = useRef(null);
  const createChild = (item) => {
    return (
      <>
        <span style={{ marginLeft: "6px" }}>
          <Arrow color={active === item.id ? "#fff" : "#411108"} />
        </span>
        <div
          onClick={() => setActive(null)}
          style={{ display: item.id === active ? "block" : "none" }}
        >
          <ul style={{ width: item.width }}>
            {item.child.map((itemd) => (
              <li key={itemd.id} onClick={jumpToPage(itemd.id)}>
                <NavLink key={itemd.id} to={itemd.to}>
                  {itemd.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  const onMouseEnter = (index) => {
    setActive(index);
    console.log(menuRef.current);
    // menuRef.current.style.display = 'block'
  };

  const onMouseLeave = () => {
    setActive(null);
    // menuRef.current.style.display = 'none'
  };
  return (
    <header className={styles.header}>
      <img
        className={styles.logo}
        src={Logo}
        width={66}
        height={66}
        alt='logo'
      />
      <nav>
        {menu.map((item) => (
          <NavLink
            key={item.id}
            className={pathname === item.to ? styles.active : ""}
            to={item.to}
            onMouseEnter={() => onMouseEnter(item.id)}
            onMouseLeave={onMouseLeave}
            onClick={() => {}}
          >
            {item.title}
            {item.child && createChild(item)}
          </NavLink>
        ))}
      </nav>
      <div className={styles.action}>
        <NavLink to={"/about"}>
          <span>About Us</span>
        </NavLink>
        <em></em>
        <img src={Search} width={24} height={24} alt='search' />
      </div>
    </header>
  );
}

export default Header;
