import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater';
import styles from './index.module.scss';
import { Fragment, useEffect } from 'react';
import media1 from './images/img_quipment_1.png';
import media2 from './images/img_quipment_2.png';
import media3 from './images/img_quipment_3.png';

function Home() {
  
  const _path = [{
    title: 'Home',
    href : '/'
  },{
    title: 'Skateboarding',
    href : ''
  }];
  
  useEffect(() => {
    document.body.scrollTop = 0
  }, [])
  return (
    <Fragment>
      <Header></Header>
      <Equipment title="Skateboarding" path={_path} />
      <main className={styles.main}>
        <div className={styles.introduction}>
          <img src={media1} alt='introduction' />
          <div>
            <h1>Introduction to Skateboard Equipment</h1>
            <p>A skateboard consists of a deck, trucks, wheels, bearings, grip tape and hardware, supported with protective gear. The deck is made of a specially designed 7 layer Canadian maple. It has good elasticity, boasting high hardness and strength. The trucks are made of aluminum alloy, with high density, hardness, and wear resistance. The wheels are made from a new type of high-polymer material, providing a better skating experience. The bearings are made of high-strength chrome alloy steel, and thus work well at a high speed and for a long time. The grip tape is moderate friction and durable. The hardware is made with the hardening process of carbon steel, which upgrades its hardness and strength. The protective gear is used to prevent skaters from injuries caused by punctures and slips.</p>
          </div>
        </div>
        <div className={styles.skatepark}>
          <h1>Introduction to Mobile Skatepark</h1>
          <p className={styles.desc}>Capable of being assembled and disassembled easily, a mobile skatepark is a multi-purpose facility that can meet demands from various groups of skateboarders, enabling more to enjoy the fun of the sport of skateboarding. A mobile skatepark boasts the following advantages:</p>
          <ul>
            <li>
              <img src={media2} alt="skatepark1" />
              <h2>Standardized production</h2>
              <p className={styles.desc}>All the structural components are manufactured in the factory, to reduce the error generated to the millimeter range, which thus greatly improves the product quality.</p>
            </li>
            <li>
              <img src={media3} alt="skatepark1" />
              <h2>Modularized disassembly</h2>
              <p className={styles.desc}>The support structures and usable surfaces are independent of each other, and can be assembled or dissembled quickly by two to three people. It takes eight steps at most to finish the installation of a single prop module.</p>
            </li>
          </ul>
          <div className={styles.combination}>
            <h2>Diversified combination</h2>
            <p>The design is standardized, with basic prop units being modular, thus allowing for different configurations and combinations based on the size of the venue and functional requirements. The surface material of the props can withstand extremely high temperature of up to 180 degrees and extremely low temperature of minus 20 degrees. Its impact resistance is greater than 40N, and the tensile strength is greater than 160N/mm².</p>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default Home;