import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater'
import styles from './index.module.scss'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { album } from '../home/data'
import Play from '../home/images/btn_play@2x.png'

function CreateVideo({list, setList}) {
  // const [list, setList] = useState([])
  const playVideo = (index, id) => {
    list[index].contro = true
    setList(list.map(item => item))
    document.getElementById('video' + id).play()
  }
  return (
    <div className={styles.videoBox}>
      {list.map((item, index) => (
        <div key={index}>
          {!item.contro && (
            <img
              className={styles.play}
              src={Play}
              alt='play'
              onClick={() => playVideo(index, item.id)}
            />
          )}
          {!item.contro && (
            <img className={styles.poster} src={item.poster} alt='poster' />
          )}
          <video
            id={'video' + item.id}
            controls
            role='img'
            src={item.src}
          ></video>
        </div>
      ))}
    </div>
  )
}

function Article() {
  const params = useParams()

  const [articleInfo, setArticleInfo] = useState({})
  const [videoList, setVideoList] = useState([])

  const [_path, setPath] = useState([])

  const getContent = articleId => {
    const article = album.filter(item => item.id == articleId)[0]
    setPath([
      {
        title: 'Home',
        href: '/',
      },
      {
        title: article.title,
        href: '',
      },
    ])
    setArticleInfo(article)

    if (article.eventVideos) {
      setVideoList(article.eventVideos)
    } else {
      setVideoList([])
    }
  }

  useEffect(() => {
    if (params.articleId) {
      getContent(params.articleId)
    }


  }, [params])

  useEffect(() => {
    document.body.scrollTop = 0
  }, [])

  return (
    <Fragment>
      <Header></Header>
      <Equipment title={articleInfo.parent} path={_path} />
      <main className={styles.article}>
        <div className={styles.title}>
          <p>{articleInfo.title}</p>
          {/* <img src={articleInfo.img} alt="" /> */}
        </div>
        {
          !articleInfo.onlyOnePic && <div className={styles.introduce}>
            <img
              className={styles.mainImg}
              width={'688px'}
              height={'380px'}
              src={articleInfo.imgDetail}
              alt=''
            />

            <div
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
          </div>
        }
        {
          articleInfo.onlyOnePic && <div className={styles.introduce}>
            <img
              className={styles.mainImg}
              width="1400"
              height="550"
              src={articleInfo.imgDetail}
              alt=''
            />
          </div>
        }
        
        
        {
          (articleInfo.eventNews && articleInfo.eventNews.length > 0) && (
          <>
            <div className={styles.sectionTitle}>Event News</div>
            <div className={styles.newsContainer}>
              {articleInfo.eventNews.map((item, index) => {
                return (
                  <div key={index} className={styles.newsItem}>
                    <div className={styles.newsImg}>
                      <img src={item.img} alt='' />
                    </div>

                    {/* <p className={styles.newsBrief}>
                      <span>{item.title}</span>
                      <span>{item.time}</span>
                    </p> */}
                  </div>
                )
              })}
            </div>
          </>
          )
        }

        {
          videoList.length > 0 && <>
            <div className={styles.sectionTitle}>Event Videos</div>

            <CreateVideo list={videoList} setList={setVideoList} />
          </>
        }
        
      </main>
      <Footer></Footer>
    </Fragment>
  )
}

export default Article
