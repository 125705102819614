import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater'
import styles from './index.rem.module.scss'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { album } from '../home/data'
import Card from './Card'

function Article() {
  const params = useParams()

  const [articleInfo, setArticleInfo] = useState({})

  const [_path, setPath] = useState([])

  const [eventNews, setEventNews] = useState([])
  const [eventVideos, setEventVideos] = useState([])

  const getContent = articleId => {
    const article = album.filter(item => item.id == articleId)[0]
    setPath([
      {
        title: 'Home',
        href: '/',
      },
      {
        title: article.title,
        href: '',
      },
    ])
    setArticleInfo(article)
    if (article.eventNews) {
      setEventNews(article.eventNews)
    }
    if (article.eventVideos) {
      setEventVideos(article.eventVideos)
    } else {
      setEventVideos([])
    }
  }

  useEffect(() => {
    if (params.articleId) {
      getContent(params.articleId)
    }
  }, [params])

  return (
    <Fragment>
      <Header></Header>
      <Equipment title={articleInfo.parent} path={_path} />
      <main className={styles.article}>
        <div className={styles.title}>
          <p>{articleInfo.title}</p>
        </div>
        {
          !articleInfo.imgDetailExtra && <>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
            <div className={styles.imgWrap}>
              <img style={{ width: '100%' }} src={articleInfo.imgDetail} alt='' />
            </div>
          </>
        }

        {
          articleInfo.imgDetailExtra && <>
          <div className={styles.imgWrap}>
              <img style={{ width: '100%', marginBottom: '20px' }} src={articleInfo.imgDetail} alt='' />
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
            <div className={styles.imgWrap}>
              <img style={{ width: '100%' }} src={articleInfo.imgDetailExtra} alt='' />
            </div>
          </>
        }
        
        {eventNews && eventNews.length > 0 && (
          <div style={{background: '#FCF7F7', padding: '1px 0 32px 0'}}>
            <div className={styles.sectionTitle}>Event News</div>
            <Card className={styles.customCard} list={eventNews} type={'album'} />
          </div>
        )}
        {
          eventVideos && eventVideos.length > 0 && (
            <>
              <div className={styles.sectionTitle}>Event Videos</div>
              <Card className={styles.customCard} list={eventVideos} type={'video'}></Card>
            </>
          )
        }
      </main>
      <div style={{marginTop: '40px'}}>
        <Footer></Footer>
      </div>
    </Fragment>
  )
}

export default Article
