import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater'
// import styles from './index.module.scss';
import bStyles from './boxing.module.scss'
import { Fragment, useEffect } from 'react'
import media1 from './images/img_quipment_1.png'
import media2 from './images/img_quipment_2.png'
import media3 from './images/img_quipment_3.png'
import equip1 from './images/boxing-equip-1.png'
import equip2 from './images/boxing-equip-2.png'
import equip3 from './images/boxing-equip-3.png'
import equip4 from './images/boxing-equip-4.png'
// import equip5 from './images/boxing-equip-5.png'
import equip6 from './images/boxing-equip-6.png'
import equip7 from './images/boxing-equip-7.png'
import icon1 from './images/boxing-icon-1.png'
import icon2 from './images/boxing-icon-2.png'
import icon3 from './images/boxing-icon-3.png'
import icon4 from './images/boxing-icon-4.png'
import icon5 from './images/boxing-icon-5.png'
import icon6 from './images/boxing-icon-6.png'
function Home() {
  const _path = [
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'AI-Powered Punching Bag',
      href: '',
    },
  ]

  useEffect(() => {
    document.body.scrollTop = 0
  }, [])
  return (
    <Fragment>
      <Header></Header>
      <Equipment title='Equipment Solutions' path={_path} />
      <main className={bStyles.main}>
        <div className={bStyles.boxing}>
        <div className={bStyles.pageTitle}>AI-Powered Punching Bag</div>
          <div className={bStyles.top}>
            <div className={bStyles.topTitle}>The Punching bag with AI</div>
            <div className={bStyles.contentWrap}>
              <div className={bStyles.contentItem}>
               <b>AI-powered coaching:</b> Real-time feedback from sensors for every
                punch
              </div>
              <div className={bStyles.contentItem}>
                <b>Performance data</b> tracking and analytics
              </div>
              <div className={bStyles.contentItem}>
                <b>Technique and hit zone</b> instant identification
              </div>
              <div className={bStyles.contentItem}>
                All-in-one metrics: <b> Speed, power, quantity, accuracy tracking</b>
              </div>
              <div className={bStyles.contentItem}><b>Deep learning algorithm</b></div>
              <div className={bStyles.contentItem}>
              Adaptive <b>personalized and guidance</b> training mode
              </div>
              <div className={bStyles.contentItem} style={{lineHeight: '40px'}}>
                <b>AI-enhanced App</b> connectivity
                {/* <br /> */}
              
                <p style={{ height: '20px', lineHeight: '20px', fontSize: '18px'}}>(under development)</p>
                
              </div>
            </div>
            <div className={bStyles.imgDesc}>
              <div
                style={{
                  fontSize: '20px',
                  color: '#99746E',
                  fontWeight: 700,
                }}
              >
                Size
              </div>
              <div style={{ color: '#99746E', fontSize: '17px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                <div>Height&nbsp;&nbsp;180cm</div>
                <div>Calibre&nbsp;&nbsp;38cm</div>
                <div>Weight&nbsp;&nbsp;70kg</div>
              </div>
            </div>
            <img
              className={bStyles.topImg1}
              width={192}
              height={680}
              src={equip1}
              alt=''
            />
          </div>
          <div className={bStyles.middle}>
            <div
              className={bStyles.factor}
              style={{ top: '155px', left: '100px' }}
            >
              <img src={icon1} width={88} height={88} alt='' />
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#6D4C47',
                  marginTop: '20px',
                  fontSize: '24px',
                  fontWeight: 400,
                }}
              >
                <span>AI processing unit and </span>
                <span style={{ textAlign: 'right' }}>sensors</span>
              </span>
            </div>
            <div
              className={bStyles.factor}
              style={{ top: '423px', left: '30px' }}
            >
              <img src={icon2} width={88} height={88} alt='' />
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#6D4C47',
                  marginTop: '20px',
                  fontSize: '24px',
                  fontWeight: 400,
                }}
              >
                <span>Interactive training light spot</span>
                {/* <span style={{ textAlign: 'right' }}>spot</span> */}
              </span>
            </div>

            <div
              className={bStyles.factor}
              style={{ top: '697px', left: '227px' }}
            >
              <img src={icon3} width={88} height={88} alt='' />
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#6D4C47',
                  marginTop: '20px',
                  fontSize: '24px',
                  fontWeight: 400,
                }}
              >
                <span>water core</span>
                {/* <span style={{ textAlign: 'right' }}>spot</span> */}
              </span>
            </div>

            <div
              className={bStyles.factor}
              style={{ top: '649px', right: '28px', alignItems: 'flex-start' }}
            >
              <img src={icon5} width={88} height={88} alt='' />
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#6D4C47',
                  marginTop: '20px',
                  fontSize: '24px',
                  fontWeight: 400,
                }}
              >
                <span>Durable High-tech surface</span>
                <span>materials</span>
              </span>
            </div>

            <div
              className={bStyles.factor}
              style={{ top: '314px', right: '28px', alignItems: 'flex-start' }}
            >
              <img src={icon4} width={88} height={88} alt='' />
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#6D4C47',
                  marginTop: '20px',
                  fontSize: '24px',
                  fontWeight: 400,
                }}
              >
                <span>Integrated surround sound</span>
                {/* <span style={{ textAlign: 'right' }}>spot</span> */}
              </span>
            </div>

            <img
              style={{ position: 'absolute', top: '100px', right: '26px' }}
              width={246}
              height={116}
              src={icon6}
              alt=''
            />

            <img width={786} height={766} src={equip2} alt='' />
          </div>
          <div className={bStyles.bottom}>
            <div className={bStyles.title}>
            Interactive between boxers and AI
            </div>
            <div style={{ display: 'flex', height: '543px', gap: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '732px',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    background: '#FCF7F7',
                    height: '220px',
                    boxSizing: 'border-box',
                    paddingLeft: '40px',
                    paddingTop: '40px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 700,
                      fontSize: '40px',
                      color: '#411108',
                    }}
                  >
                    AI Deep Learning Algorithms
                  </p>
                  <p
                    style={{
                      // fontWeight: 700,
                      fontSize: '20px',
                      color: '#6D4C47',
                      marginTop: '24px'
                    }}
                  >
                    By incorporating training data and utilizing deep learning
                    algorithms, a personalized training model can be created.
                  </p>
                </div>
                <div
                  style={{
                    background: '#FCF7F7',
                    height: '304px',
                    display: 'flex',
                    gap: '34px',
                  }}
                >
                  <img src={equip3} alt='' width={268} height={304} />
                  <div>
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: '40px',
                        color: '#411108',
                        marginTop: '62px',
                        marginBottom: '24px',
                      }}
                    >
                      Sensors
                    </p>
                    <p style={{ fontSize: '20px', color: '#6D4C47' }}>
                      Capture every punch with precision sensors, charting
                      strike zones and combo patterns unique to each boxer.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '648px',
                  gap: '48px',
                  background: '#FCF7F7',
                }}
              >
                <div
                  style={{
                    background: '#FCF7F7',
                    flex: 1,
                    paddingLeft: '57px',
                    paddingTop: '40px',
                    boxSizing: 'border-box',
                  }}
                >
                  <p
                    style={{
                      width: '208px',
                      height: '60px',
                      lineHeight: '60px',
                      background: '#6D4C47',
                      fontSize: '27px',
                      color: '#fff',
                      fontWeight: 700,
                      textAlign: 'center',
                      borderRadius: '6px',
                      margin: '0 auto',
                      fontStyle: 'italic'
                    }}
                  >
                    AI-Powered
                  </p>
                  <p
                    style={{
                      // width: '238px',
                      height: '36px',
                      lineHeight: '36px',
                      // background: '#6D4C47',
                      fontSize: '27px',
                      color: '#6D4C47',
                      textAlign: 'center',
                      fontWeight: 700,
                      width: '208px',
                      margin: '0 auto',
                      marginTop: '8px',
                      marginBottom: '20px',
                      fontStyle: 'italic'
                    }}
                  >
                    Punching Bag
                  </p>
                  <img width={273} height={368} src={equip4} alt='' />
                </div>
                <div
                  style={{
                    background: '#FCF7F7',
                    width: '286px',
                    boxSizing: 'border-box',
                    paddingTop: '30px',
                  }}
                >
                  <img src={equip6} width={230} height={520} alt='' />
                </div>
              </div>
            </div>
            <div
              style={{
                height: '278px',
                background: '#FCF7F7',
                boxSizing: 'border-box',
                padding: '40px',
                marginTop: '20px',
                position: 'relative'
              }}
            >
              <img width={136} height={ 104 }  src={equip7} alt="" style={{position: 'absolute', right: 0, bottom: 0}} />
              <div
                style={{
                  fontWeight: 700,
                  fontSize: '40px',
                  height: '56px',
                  lineHeight: '56px',
                  color: '#411108',
                }}
              >
                AI-powered boxing tutorials
              </div>
              <p
                style={{
                  height: '28px',
                  lineHeight: '28px',
                  fontSize: '20px',
                  color: '#6D4C47',
                  marginTop: '24px',
                }}
              >
                Analytics and feedback on professional boxing videos at
                different technical levels.
              </p>
              <p
                style={{
                  height: '28px',
                  lineHeight: '28px',
                  fontSize: '20px',
                  color: '#6D4C47',
                  marginTop: '12px',
                }}
              >
                By synchronizing the videos with the LED light spots embedded in
                the surface of the punching bag, interactive guidance can be
                provided.
              </p>
              <p
                style={{
                  height: '28px',
                  lineHeight: '28px',
                  fontSize: '20px',
                  color: '#6D4C47',
                  marginTop: '12px',
                }}
              >
                Enabling boxers to master the complex offensive maneuvers of
                elite competitors.
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  )
}

export default Home
