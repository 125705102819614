import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import styles from './index.module.scss';
import Equipment from '../components/navigater';
import Detail from '../components/detail';
import { Fragment } from 'react';

function Details() {
    const _path = [{
        title: 'Home',
        href: '/'
    }, {
        title: 'Details',
        href: ''
    }];

    return (
        <Fragment>
            <Header></Header>
            <Equipment title="Details" path={_path} />
            <main className={styles.main}>
                <Detail></Detail>
            </main>
            <Footer></Footer>
        </Fragment>
    );
}

export default Details;