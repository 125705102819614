import Title from "./title.js";
import styles from "./CardRowList.module.scss";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { isTablet } from "react-device-detect";
import Card from "./Card.js";
function CardList(props) {
  const [showAll, setShowAll] = useState(false);

  const [list, setList] = useState(props.list);

  const createAlbum = () => {
    return (
      <>
        <ul
          style={{ width: list.length * 196 + "px" }}
          className={`${styles.album} ${isTablet ? styles.tabletAlbum : ""}`}
        >
          {list.map((item, index) => {
            return <Card key={index} item={item} />;
          })}
        </ul>
      </>
    );
  };

  return (
    <div className={`${styles.card} ${props.className}`}>
      {props.title ? <Title title={props.title} /> : ""}
      <div className={styles["list-box"]}>{createAlbum()}</div>
    </div>
  );
}

export default CardList;
