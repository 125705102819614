import styles from "./Card.module.scss";
import { NavLink } from "react-router-dom";
function Card({ item }) {
  return (
    <li className={styles['item']}>
      <NavLink to={"/news-detail/" + item.id}>
        <div
          className={styles.bg}
          style={{
            background: `url('${item.img}') no-repeat center`,
            backgroundSize: "cover",
          }}
        >
          <p className={`${styles.date} `}> {item.date}</p>
        </div>
      </NavLink>

      <h2>{item.title}</h2>
      <NavLink to={"/news-detail/" + item.id}>
        <p>{item.desc}</p>
      </NavLink>
    </li>
  );
}

export default Card;
