import Title from './title'
import Play from './images/btn_play@2x.png'
import Arrow from '../components/header/icon_top_arrow'
import styles from './home.rem.module.scss'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { isTablet } from 'react-device-detect';
function Card(props) {
  const [showAll, setShowAll] = useState(false)

  const changeState = () => setShowAll(!showAll)

  const [list, setList] = useState(props.list)

  const playVideo = (index, id) => {
    list[index].contro = true
    setList(list.map(item => item))
    document.getElementById('video' + id).play()
  }

  const createAlbum = () => {
    return (
      <>
        <ul
          className={`${
            showAll ? `${styles.album} ${styles.albumShowAll}` : styles.album
            } ${isTablet ? styles.tabletAlbum : ''}`}
            
        >
          {list.map((item, index) => {
            return (
              <li key={index}>
                <NavLink to={item.to}>
                <div
                  className={styles.bg}
                  style={{
                    background: `url('${item.img}') no-repeat`,
                    backgroundSize: '100% auto',
                  }}
                ></div>
                </NavLink>
                
                <h2>{item.title}</h2>
                <NavLink to={item.to}>
                  <p>{item.desc}</p>
                </NavLink>
              </li>
            )
          })}
        </ul>
        {!showAll && props.hasMore && (
          <div className={styles.loadMore} onClick={changeState}>
            View all
            <Arrow color='#6D4C47' />
          </div>
        )}
      </>
    )
  }

  const createVideo = () => {
    return (
      <div className={styles.videoBox}>
        {props.list.map((item, index) => (
          <div key={index}>
            {!item.contro && (
              <img
                className={styles.play}
                src={Play}
                alt='play'
                onClick={() => playVideo(index, item.id)}
              />
            )}
            {!item.contro && (
              <img className={styles.poster} src={item.poster} alt='poster' />
            )}
            <video
              id={'video' + item.id}
              controls
              role='img'
              src={item.src}
            ></video>
          </div>
        ))}
      </div>
    )
  }

  const _render = () => {
    switch (props.type) {
      case 'album':
        return createAlbum()
      case 'video':
        return createVideo()
      default:
        return <></>
    }
  }

  return (
    <div className={`${styles.card} ${props.className}`}>
      { props.title ? <Title title={props.title} /> : ''}
      {_render()}
    </div>
  )
}

export default Card
