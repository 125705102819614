/*
 * @Description: 网格布局
 * @Author: 翁庆雨 <wqy.mail@foxmail.com>
 * @Date: 2024-06-12 15:33:08
 * @LastEditTime: 2024-06-14 21:13:19
 * @LastEditors: 翁庆雨  <wqy.mail@foxmail.com>
 * @FilePath: /xempowerusa/src/sports-en/news/components/Grid.js
 */
import Card from "./Card";
import styles from "./Grid.module.scss";
const Grid = ({ list }) => {
  return (
    <div className={styles["grid-box"]}>
      {list.map((item, index) => {
        return  <Card item={item} index={index} key={index} />;
      })}
    </div>
  );
};
export default Grid;
