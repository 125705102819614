import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Equipment from "../components/navigater";
import styles from "./index.rem.module.scss";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { albumList } from "../news/listData";

function NewsDetail() {
  const params = useParams();

  const [articleInfo, setArticleInfo] = useState({});
  const [_path, setPath] = useState([
    {
      title: "Home",
      href: "/",
    },
    {
      title: "News",
      href: "",
    },
  ]);

  const getContent = (newsId) => {
    console.log(newsId);
    const article = albumList.filter((item) => item.id == newsId)[0];
    setPath([
      {
        title: "Home",
        href: "/",
      },
      {
        title: "News",
        href: "",
      },
    ]);
    setArticleInfo(article);
  };

  useEffect(() => {
    if (params.newsId) {
      getContent(params.newsId);
    }
  }, [params]);

  return (
    <Fragment>
      <Header></Header>
      <Equipment title={"News"} path={_path} />
      <main className={styles.article}>
        <div className={styles["article-title"]}>
          <p>{articleInfo.title}</p>
          <span>{articleInfo.date}</span>
        </div>
        <div className={styles["article-content-box"]}>
          <img
            className={styles["header-img"]}
            src={articleInfo.imgDetail}
            alt=''
          />
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: articleInfo.editor }}
          ></div>
          <div className={styles["img-box"]}>
            {articleInfo?.eventNews?.map((item, index) => {
              return <img src={item.img} key={index}></img>;
            })}
          </div>
        </div>
      </main>
      <div style={{ marginTop: "40px" }}>
        <Footer></Footer>
      </div>
    </Fragment>
  );
}

export default NewsDetail;
