import Title from './title'
import Play from './images/btn_play@2x.png'
import styles from './home.module.scss'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import Left from './images/icon_left.png'
import Right from './images/icon_right.png'

function Card(props) {
  const [list, setList] = useState(props.list)

  const [scrollX, setScrollX] = useState(0)

  const [num, setNum] = useState(0)

  const itemWidth = 515

  const moveLeft = () => {
    if (num < props.num) {
      setScrollX(scrollX - itemWidth * 3)
      setNum(num + 1)
    }
  }

  const moveRight = () => {
    if (scrollX !== 0) {
      setNum(num - 1)
      setScrollX(scrollX + itemWidth * 3)
    }
  }

  const createAlbum = () => {
    const boxWidth = list.length * 539
    return (
      <div className={styles.album}>
        <div className={styles.swiper} style={{ width: `${boxWidth}px` }}>
          <div
            className={styles.scroll}
            style={{ transform: `translateX(${scrollX}px)` }}
          >
            {list.filter(item => !item.skipped).map((item, index) => (
              <div key={index} className={styles.swiperItem}>
                <NavLink to={item.to}>
                <div
                  className={styles.swiperItemImg}
                  style={{ background: `url('${item.img}') no-repeat` }}
                ></div>
                </NavLink>
                
                <NavLink
                  className={`${
                    props.row === 2
                      ? styles.swiperItemTitleRow
                      : styles.swiperItemTitle
                  }`}
                  to={item.to}
                >
                  {item.title}
                </NavLink>
                <NavLink className={styles.swiperItemDesc} to={item.to}>
                  <span className={styles.brief}>{item.desc}</span>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
        {
          props.showMore && <div className={styles.action}>
          <div>
            <img
              src={Left}
              className={`${scrollX === 0 ? styles.dis : ''}`}
              alt='left'
              onClick={moveRight}
            />
          </div>
          <div>
            <img
              src={Right}
              className={`${num >= props.num ? styles.dis : ''}`}
              alt='right'
              onClick={moveLeft}
            />
          </div>
        </div>
        }
        
      </div>
    )
  }

  const playVideo = (index, id) => {
    list[index].contro = true
    setList(list.map(item => item))
    document.getElementById('video' + id).play()
  }

  const createVideo = () => {
    return (
      <div className={styles.videoBox}>
        {list.map((item, index) => (
          <div key={index}>
            {!item.contro && (
              <img
                className={styles.play}
                src={Play}
                alt='play'
                onClick={() => playVideo(index, item.id)}
              />
            )}
            {!item.contro && (
              <img className={styles.poster} src={item.poster} alt='poster' />
            )}
            <video
              id={'video' + item.id}
              controls
              role='img'
              src={item.src}
            ></video>
          </div>
        ))}
      </div>
    )
  }

  const _render = () => {
    switch (props.type) {
      case 'album':
        return createAlbum()
      case 'video':
        return createVideo()
      default:
        return <></>
    }
  }
  return (
    <div className={`${styles.card} ${props.className}`}>
      <Title title={props.title} />
      {_render()}
    </div>
  )
}

export default Card
