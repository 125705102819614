import { Fragment } from 'react';
import { NavLink } from "react-router-dom";
import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Card from './card';
import Title from './title';
import styles from './home.module.scss';
import { album, smart, video, video3 } from './data';

function Home() {

  // const _width = window.innerWidth;

  return (
    <Fragment>
      <Header></Header>
      <main className={styles.main}>
        <div className={styles.banner}>
          {/* <h1><i>EVENT OPERATION</i></h1> */}
          {/* <h1><i>EVENT SYSTEMS</i></h1> */}
          {/* <h1><i>EQUIPMENT SOLUTIONS</i></h1> */}
        </div>
        <div className={styles.centerBox}>
          <Card  className={styles.customCard} list={album} type={'album'} title={'Event Operation'} num={2} showMore={true}></Card>
          {/* <Card className={`${styles.customCard} ${styles.m2}`} list={video} type={'video'} title={'Event Videos'}></Card> */}
          <Card className={`${styles.customCard} ${styles.m3}`} list={smart} type={'album'} title={'Event Systems'} row={2} num={1} showMore={true}></Card>
          {/* <Card className={`${styles.customCard} ${styles.m4}`} list={video3} type={'video'} title={'Systems Video'}></Card> */}
          <Title title={'Equipment Solutions'} />
        </div>

        <div className={styles.equipment}>
          <div>
            <h2>Introduction to Skateboard Equipment</h2>
            {/* <p>A skateboard usually consists of a deck, grip tape, trucks, wheels, bearings and hardware, supported with protective gear. The deck is wear-resistant, environmentally friendly and aesthetically appealing, boasting high hardness and strength, and good safety performance. The grip tape is environmentally friendly, relatively thin, light, durable, and cold-resistant. The trucks are made of aluminum alloy, with high density, hardness, and wear resistance. The wheels are made from a new type of high-polymer material, providing a better skating experience. The bearings are made of high-strength chrome alloy steel, and thus work well at a high speed and for a long time. The hardware is made with the hardening process of carbon steel, which upgrades its hardness and strength, and has good anti-explosion performance. The protective gear is used to prevent skaters from injuries caused by punctures and slips.</p> */}
            <NavLink className={styles.swiperItemDesc} to={'/equipment'}>Learn More</NavLink>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default Home;