import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import styles from "./index.module.scss";
import { Fragment } from "react";
import Equipment from '../components/navigater/index'
import Title from "../components/title/title";
import { NavLink } from "react-router-dom";
import NewsTitle from "./components/NewsTitle";
import CardList from "./components/CardList";
import { albumList } from "./listData";
import Grid from "./components/Grid";
function News() {
  const _path = [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "News",
      href: "",
    },
  ];
  return (
    <Fragment>
      <Header></Header>
      <Equipment title='News' path={_path} />
      {/* <Title title='News'></Title> */}
      {/* <NewsTitle /> */}
      <main className={styles.main}>
        <div className={styles["bigTitle"]} id='All'>
          Latest News
        </div>
        <div className={styles["all-box"]}>
          <Grid list={albumList.slice(0,6)} />
          {/* <div className={`${styles["load-more"]} ${styles[""]}`}>
            Load More
          </div> */}
        </div>
        {/* <div className={styles["bigTitle"]} id='Boxing'>
          Boxing
        </div>
        <CardList
          list={albumList}
          type={"album"}
          title={"Event Operation"}
          num={2}
          showMore={albumList.length > 3}
        />
        <div className={styles["bigTitle"]} id='Sanda'>
          Sanda
        </div>
        <CardList
          list={albumList}
          type={"album"}
          title={"Event Operation"}
          num={2}
          showMore={albumList.length > 3}
        /> */}
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default News;
