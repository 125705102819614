import video1 from "../home/images/video1.png";
import video2 from "../home/images/video2.png";
import boxingPoster from "../home/images/boxingPoster.png";
import breaking2Poster from "../home/images/breaking-video2.png";
import skateboarding2Poster from "../home/images/skateboarding-video2.png";

import breaking from "../home/images/breaking.png";
import skateboard from "../home/images/skateboarding.png";
import boxing from "../home/images/boxing.png";
import breakingDetail from "../home/images/breakingDetail.png";
import skateboardDetail from "../home/images/skateboardingDetail.png";
import breakingEventNews1 from "../home/images/breaking-event-news1.png";
import breakingEventNews2 from "../home/images/breaking-event-news2.png";
import breakingEventNews3 from "../home/images/breaking-event-news3.png";
import breakingEventNews4 from "../home/images/breaking-event-news4.png";
import skateboardingEventNews1 from "../home/images/skateboarding-event-news1.png";
import skateboardingEventNews2 from "../home/images/skateboarding-event-news2.png";
import boxingDetail from "./images/usa/头图.png";
import boxingDetail2 from "./images/italy/头图.png";
import sandaDetail from "./images/wenzhou/头图.png";
import boxingEventNews1 from "./images/usa/1.png";
import boxingEventNews2 from "./images/usa/2.png";
import boxingEventNews3 from "./images/usa/3.png";
import boxingEventNews4 from "./images/usa/4.png";
import boxingEventNewsApril1 from "./images/usa/april1.jpg";
import boxingEventNewsApril2 from "./images/usa/april2.jpg";
import boxingEventNewsApril3 from "./images/usa/april3.jpg";
import boxingEventNewsApril4 from "./images/usa/april4.png";
import boxingEventNewsAprilHeader from "./images/usa/april_header.jpg";
import sandaEventNews1 from "./images/wenzhou//1.png";
import sandaEventNews2 from "./images/wenzhou//2.png";
import sandaEventNews3 from "./images/wenzhou//3.png";
import sandaEventNews4 from "./images/wenzhou//4.png";
import boxingEventNews5 from "./images/italy/1.png";
import boxingEventNews6 from "./images/italy/2.png";
import boxingEventNews7 from "./images/italy/3.png";
import boxingEventNews8 from "./images/italy/4.png";
import boxingDetail3 from "./images/boxing/0.jpg";
import boxingEventNews9 from "./images/boxing/1.jpg";
import boxingEventNews10 from "./images/boxing/2.jpg";
import boxingEventNews11 from "./images/boxing/3.jpg";
import boxingEventNews12 from "./images/boxing/4.jpg";
import sandaDetail2 from "./images/sanda/0.jpg";
import sandaEventNews9 from "./images/sanda/1.jpg";
import sandaEventNews10 from "./images/sanda/2.jpg";
import sandaEventNews11 from "./images/sanda/3.jpg";
import sandaEventNews12 from "./images/sanda/4.jpg";
export const usaBoxing = [
  {
    id: 8,
    parent: "Event Operation",
    title: "2024 USA Boxing International Invitational",
    date: `Boxing · April 2024`,
    img: boxingEventNewsAprilHeader,
    imgDetail: boxingEventNewsAprilHeader,
    desc: `
   The 2024 USA Boxing International Invitational was held in Pueblo from April 16 to April 20, 2024. A total of 120 boxers from 17 countries participated in the Invitational. Xempower provided support for this event.<br/><br/>
Our boxing event management system was adopted to offer pre-event services such as management of athletes' and officials' information, data review, tournament draw and schedule arrangement, which significantly improved the efficiency of event management. During the event, we also provided the referees with services such as timing, scoring, results processing and video review to ensure the fair play of the tournament.<br/><br/>
Xempower's boxing event management system ensured the stable and smooth running of the event. We look forward to future cooperation with USA Boxing.
    `,
    editor: `
  The 2024 USA Boxing International Invitational was held in Pueblo from April 16 to April 20, 2024. A total of 120 boxers from 17 countries participated in the Invitational. Xempower provided support for this event.<br/><br/>
Our boxing event management system was adopted to offer pre-event services such as management of athletes' and officials' information, data review, tournament draw and schedule arrangement, which significantly improved the efficiency of event management. During the event, we also provided the referees with services such as timing, scoring, results processing and video review to ensure the fair play of the tournament.<br/><br/>
Xempower's boxing event management system ensured the stable and smooth running of the event. We look forward to future cooperation with USA Boxing.
    `,
    eventNews: [
      {
        img: boxingEventNewsApril1,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNewsApril2,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNewsApril3,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNewsApril4,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [],
  },
  {
    id: 1,
    parent: "Event Operation",
    title: "2024 USA Boxing National Open",
    date: `Boxing · March 2024`,
    img: boxingDetail,
    imgDetail: boxingDetail,
    desc: `
    In March 2024, Xempower was invited to provide support for the 2024 USA Boxing National Open.<br/><br/>
Our boxing event management system was adopted to offered pre-event services such as management of athletes’ and officials’ information, data review, drawing, and event schedule arrangement, which significantly improved the event management efficiency.<br/><br/>
During the event, we provided the judging team with timing and scoring, results processing, and video reviewing services, ensuring the fairness and justice of the event.<br/><br/>
Our support services ensured the stable and efficient completion of the event and earned recognition from the event officials. We look forward to future collaborations.<br/><br/>
    `,
    editor: `
    In March 2024, Xempower was invited to provide support for the 2024 USA Boxing National Open.<br/><br/>
Our boxing event management system was adopted to offered pre-event services such as management of athletes’ and officials’ information, data review, drawing, and event schedule arrangement, which significantly improved the event management efficiency.<br/><br/>
During the event, we provided the judging team with timing and scoring, results processing, and video reviewing services, ensuring the fairness and justice of the event.<br/><br/>
Our support services ensured the stable and efficient completion of the event and earned recognition from the event officials. We look forward to future collaborations.<br/><br/>
    `,
    eventNews: [
      {
        img: boxingEventNews1,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews2,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews3,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews4,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [],
  },
];
export const newsBoxing = [
  {
    id: 12,
    parent: "Event Operation",
    title: "2024 National Wushu Sanda Championships",
    date: `Sanda · July 2024`,
    img: sandaDetail2,
    imgDetail: sandaDetail2,
    desc: `From July 3 to 10, 2024, the National Wushu Sanda Championships was grandly held at Zhanjiang Olympic Center Stadium in Guangdong Province. This national professional event attracted outstanding athletes from various provinces in China. As an important partner of the event, Xempower fully participated in pre-event works such as athletes' weigh-in, tournament draw and schedule arrangement, ensuring the smooth running of the event. <br/><br/>
Xempower's electronic timing and scoring system and replay review system played a vital role during the match. The efficient electronic timing and scoring system recorded match data in real time. The replay review system provided referees with detailed replays of matches, so that every decision could be made based on the evidence, making the scoring process more transparent and fairer.<br/><br/>
The technical support provided by Xempower for the event enhanced the enjoyment and fairness of every match. It not only increased the efficiency of the match, but also won unanimous praise from the event officials, the coaching teams, and the general audience, representing an important manifestation of the modernization and professionalization of Wushu Sanda matches.<br/>`,
    editor: `From July 3 to 10, 2024, the National Wushu Sanda Championships was grandly held at Zhanjiang Olympic Center Stadium in Guangdong Province. This national professional event attracted outstanding athletes from various provinces in China. As an important partner of the event, Xempower fully participated in pre-event works such as athletes' weigh-in, tournament draw and schedule arrangement, ensuring the smooth running of the event. <br/><br/>
Xempower's electronic timing and scoring system and replay review system played a vital role during the match. The efficient electronic timing and scoring system recorded match data in real time. The replay review system provided referees with detailed replays of matches, so that every decision could be made based on the evidence, making the scoring process more transparent and fairer.<br/><br/>
The technical support provided by Xempower for the event enhanced the enjoyment and fairness of every match. It not only increased the efficiency of the match, but also won unanimous praise from the event officials, the coaching teams, and the general audience, representing an important manifestation of the modernization and professionalization of Wushu Sanda matches.<br/>`,
    eventNews: [
      {
        img: sandaEventNews9,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: sandaEventNews10,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: sandaEventNews11,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: sandaEventNews12,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [],
  },
  {
    id: 11,
    parent: "Event Operation",
    title: "2024 Junior Olympics and Summer Festival",
    date: `Boxing · June 2024`,
    img: boxingDetail3,
    imgDetail: boxingDetail3,
    desc: `In June 2024, Xempower provided support for the 2024 Junior Olympics and Summer Festival hosted by USA Boxing in Wichita from June 23 to 29. In the tournament, we were not only responsible for arranging boxers' schedules and supporting referees in their work, but also used our AI Boxing Scoring System to assist the referees in scoring. To ensure the accuracy of referees' decisions on scoring, we set up four high-definition cameras around the ring to capture match footage in real time and used artificial intelligence (AI) to analyze and determine whether a boxer had scored or committed a foul. <br/><br/>
The use of the AI Boxing Scoring System made the scoring process fairer and more transparent. By analyzing match data in real time, AI can quickly identify and record every effective hit and committed foul by boxers, greatly improving the efficiency and accuracy of referees' decisions on scoring.<br/><br/>
The successful application of our AI Boxing Scoring System in this tournament not only demonstrated our innovative capabilities in event management and scoring technology, but also laid a solid foundation for the promotion and application of our AI technologies in more international events in the future.<br/>`,
    editor: `In June 2024, Xempower provided support for the 2024 Junior Olympics and Summer Festival hosted by USA Boxing in Wichita from June 23 to 29. In the tournament, we were not only responsible for arranging boxers' schedules and supporting referees in their work, but also used our AI Boxing Scoring System to assist the referees in scoring. To ensure the accuracy of referees' decisions on scoring, we set up four high-definition cameras around the ring to capture match footage in real time and used artificial intelligence (AI) to analyze and determine whether a boxer had scored or committed a foul. <br/><br/>
The use of the AI Boxing Scoring System made the scoring process fairer and more transparent. By analyzing match data in real time, AI can quickly identify and record every effective hit and committed foul by boxers, greatly improving the efficiency and accuracy of referees' decisions on scoring.<br/><br/>
The successful application of our AI Boxing Scoring System in this tournament not only demonstrated our innovative capabilities in event management and scoring technology, but also laid a solid foundation for the promotion and application of our AI technologies in more international events in the future.<br/>`,
    eventNews: [
      {
        img: boxingEventNews9,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews10,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews11,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews12,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [],
  },
];
export const italyBoxing = {
  id: 2,
  parent: "Event Operation",
  title: "2024 World Qualifier 1 Tournament",
  date: `Boxing · March 2024`,
  img: boxingDetail2,
  imgDetail: boxingDetail2,
  desc: `In March 2024, we visited the 2024 World Qualifier 1 Tournament in Italy and demonstrated our AI-powered Boxing Scoring System to the event officials. <br/><br/>
At the training center, the participating teams from different countries tried out our AI system and used our boxing gloves for some 1v1 training and bag punch training. They expressed their approval of the accuracy of our analysis.<br/><br/>
Event officials, after gaining a detailed understanding of our AI analysis system and event management system, also acknowledged our capabilities and expressed interest in further collaboration. <br/>`,
  editor: `In March 2024, we visited the 2024 World Qualifier 1 Tournament in Italy and demonstrated our AI-powered Boxing Scoring System to the event officials. <br/><br/>
At the training center, the participating teams from different countries tried out our AI system and used our boxing gloves for some 1v1 training and bag punch training. They expressed their approval of the accuracy of our analysis.<br/><br/>
Event officials, after gaining a detailed understanding of our AI analysis system and event management system, also acknowledged our capabilities and expressed interest in further collaboration. <br/>`,
  eventNews: [
    {
      img: boxingEventNews5,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
    {
      img: boxingEventNews6,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
    {
      img: boxingEventNews7,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
    {
      img: boxingEventNews8,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
  ],
  eventVideos: [],
};
export const wenzhouSanda = {
  id: 3,
  parent: "Event Operation",
  title: "2024 Wenzhou Youth Sanda Championship",
  date: `Sanda · May 2024`,
  img: sandaDetail,
  imgDetail: sandaDetail,
  desc: `We were invited to provide support for the 2024 Wenzhou Youth Sanda Championship, where our independently developed Xempower Sanda Event Management System was adopted.<br/><br/>
By seamlessly coordinating multiple systems, including timing and scoring, results processing, and video reviewing, we featured the event with the event scheduling, timing and scoring, query and output of results, live recording and playback, etc., providing robust support for the event.<br/><br/>
The event officials highly praised the comprehensiveness and ease of use of the system, and they also offered some suggestions for optimization. We believe that we can provide even better services for more events in the future.<br/><br/>`,
  editor: `We were invited to provide support for the 2024 Wenzhou Youth Sanda Championship, where our independently developed Xempower Sanda Event Management System was adopted.<br/><br/>
By seamlessly coordinating multiple systems, including timing and scoring, results processing, and video reviewing, we featured the event with the event scheduling, timing and scoring, query and output of results, live recording and playback, etc., providing robust support for the event.<br/><br/>
The event officials highly praised the comprehensiveness and ease of use of the system, and they also offered some suggestions for optimization. We believe that we can provide even better services for more events in the future.<br/><br/>`,
  eventNews: [
    {
      img: sandaEventNews1,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
    {
      img: sandaEventNews2,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
    {
      img: sandaEventNews3,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
    {
      img: sandaEventNews4,
      title: "Boxing Boxing Boxing",
      time: "2023.11.03~11.04",
    },
  ],
  eventVideos: [],
};
export const albumList = [
  ...newsBoxing,
  wenzhouSanda,
  ...usaBoxing,
  italyBoxing,
  {
    id: 4,
    parent: "Event Operation",
    title: "Boxing",
    desc: "We are adept at brand packaging and commercial operations, to ensure competitiveness and entertainment of the boxing sport. By leveraging high-quality content IP, we engage in cooperation in various aspects, for example, seeking cooperation with sports celebrities, to establish a complete and mature market-oriented operation system that is more scientific, systematic, and international.",
    img: boxing,
    imgDetail: boxingDetail,
    editor: `
    We are adept at brand packaging and commercial operations, to ensure competitiveness and entertainment of the boxing sport. By leveraging high-quality content IP, we engage in cooperation in various aspects, for example, seeking cooperation with sports celebrities, to establish a complete and mature market-oriented operation system that is more scientific, systematic, and international.
    `,
    eventNews: [
      {
        img: boxingEventNews1,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews2,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews3,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews4,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [],
  },
  {
    id: 5,
    parent: "Event Operation",
    title: "Breaking",
    desc: "We host breaking events and provide a wide range of services for those who seek to develop their own events. These services include event registration, streaming services, and merchandising. We provide a stage for break dancers to showcase their skills, promote the exchange and integration of breaking culture, contribute to the professional development of breaking, and enhance its competitive level and influence worldwide.",
    img: breaking,
    imgDetail: breakingDetail,
    editor: `
    We host breaking events and provide a wide range of services for those who seek to develop their own events. These services include event registration, streaming services, and merchandising. We provide a stage for break dancers to showcase their skills, promote the exchange and integration of breaking culture, contribute to the professional development of breaking, and enhance its competitive level and influence worldwide.
    `,
    eventNews: [
      {
        img: breakingEventNews1,
        title: "breaking Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: breakingEventNews2,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: breakingEventNews3,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: breakingEventNews4,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [
      {
        poster: video2,
        contro: false,
        id: 1,
        src: "https://www.xempowerusa.com/videos/breaking.MP4",
      },
      {
        poster: breaking2Poster,
        contro: false,
        id: 2,
        src: "https://www.xempowerusa.com/videos/break2.m4v",
      },
    ],
  },
  {
    id: 6,
    parent: "Event Operation",
    title: "Skateboarding",
    desc: "Skateboarding was recognized by the IOC as an official Olympic sport in 2016. Skateboarding in its most competitive form consists of two events: Street and Park. We host skating events and provide services such as: media operations, support event operations, talent management, big data processing, intelligent device implementation, and independent sports brand development. ",
    img: skateboard,
    imgDetail: skateboardDetail,
    editor: `
    Skateboarding was recognized by the IOC as an official Olympic sport in 2016. Skateboarding in its most competitive form consists of two events: Street and Park. We host skating events and provide services such as: media operations, support event operations, talent management, big data processing, intelligent device implementation, and independent sports brand development. 
    `,
    eventNews: [
      {
        img: skateboardingEventNews2,
        title: "",
        time: "",
      },
      {
        img: skateboardingEventNews1,
        title: "",
        time: "",
      },
    ],
    eventVideos: [
      {
        poster: video1,
        id: 1,
        contro: false,
        src: "https://www.xempowerusa.com/videos/skateboarding.MP4",
      },
      {
        poster: skateboarding2Poster,
        id: 2,
        contro: false,
        src: "https://www.xempowerusa.com/videos/20240111.MP4",
      },
    ],
  },
  {
    id: 7,
    parent: "Event Operation",
    title: "Boxing",
    desc: "We are adept at brand packaging and commercial operations, to ensure competitiveness and entertainment of the boxing sport. By leveraging high-quality content IP, we engage in cooperation in various aspects, for example, seeking cooperation with sports celebrities, to establish a complete and mature market-oriented operation system that is more scientific, systematic, and international.",
    img: boxing,
    imgDetail: boxingDetail,
    editor: `
    We are adept at brand packaging and commercial operations, to ensure competitiveness and entertainment of the boxing sport. By leveraging high-quality content IP, we engage in cooperation in various aspects, for example, seeking cooperation with sports celebrities, to establish a complete and mature market-oriented operation system that is more scientific, systematic, and international.
    `,
    eventNews: [
      {
        img: boxingEventNews1,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews2,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews3,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: boxingEventNews4,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [],
  },
  {
    id: 8,
    parent: "Event Operation",
    title: "Breaking",
    desc: "We host breaking events and provide a wide range of services for those who seek to develop their own events. These services include event registration, streaming services, and merchandising. We provide a stage for break dancers to showcase their skills, promote the exchange and integration of breaking culture, contribute to the professional development of breaking, and enhance its competitive level and influence worldwide.",
    img: breaking,
    imgDetail: breakingDetail,
    editor: `
    We host breaking events and provide a wide range of services for those who seek to develop their own events. These services include event registration, streaming services, and merchandising. We provide a stage for break dancers to showcase their skills, promote the exchange and integration of breaking culture, contribute to the professional development of breaking, and enhance its competitive level and influence worldwide.
    `,
    eventNews: [
      {
        img: breakingEventNews1,
        title: "breaking Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: breakingEventNews2,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: breakingEventNews3,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
      {
        img: breakingEventNews4,
        title: "Boxing Boxing Boxing",
        time: "2023.11.03~11.04",
      },
    ],
    eventVideos: [
      {
        poster: video2,
        contro: false,
        id: 1,
        src: "https://www.xempowerusa.com/videos/breaking.MP4",
      },
      {
        poster: breaking2Poster,
        contro: false,
        id: 2,
        src: "https://www.xempowerusa.com/videos/break2.m4v",
      },
    ],
  },
  {
    id: 9,
    parent: "Event Operation",
    title: "Skateboarding",
    desc: "Skateboarding was recognized by the IOC as an official Olympic sport in 2016. Skateboarding in its most competitive form consists of two events: Street and Park. We host skating events and provide services such as: media operations, support event operations, talent management, big data processing, intelligent device implementation, and independent sports brand development. ",
    img: skateboard,
    imgDetail: skateboardDetail,
    editor: `
    Skateboarding was recognized by the IOC as an official Olympic sport in 2016. Skateboarding in its most competitive form consists of two events: Street and Park. We host skating events and provide services such as: media operations, support event operations, talent management, big data processing, intelligent device implementation, and independent sports brand development. 
    `,
    eventNews: [
      {
        img: skateboardingEventNews2,
        title: "",
        time: "",
      },
      {
        img: skateboardingEventNews1,
        title: "",
        time: "",
      },
    ],
    eventVideos: [
      {
        poster: video1,
        id: 1,
        contro: false,
        src: "https://www.xempowerusa.com/videos/skateboarding.MP4",
      },
      {
        poster: skateboarding2Poster,
        id: 2,
        contro: false,
        src: "https://www.xempowerusa.com/videos/20240111.MP4",
      },
    ],
  },
];
