import styles from './index.module.scss';
import { NavLink } from "react-router-dom";
function Navigater(props) {

  return (
    <div className={styles.navigater}>
      <div>
        <h1>{props.title}</h1>
        <p className={styles.path}>
          {
            props.path.map( (item, index) => item.href ? <NavLink key={index} to={item.href}>{item.title} / </NavLink> : <span key={index}>{item.title}</span>)
          }
        </p>
      </div>
    </div>
  );
}

export default Navigater;