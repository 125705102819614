import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import styles from "./index.rem.module.scss";
import Equipment from '../components/navigater'
import { Fragment } from "react";
import Title from "../components/title/title";
import { NavLink } from "react-router-dom";
import Bg from "./images/img_news_banner.jpg";
import CardList from "./components/CardList";
import CardRowList from "./components/CardRowList";
import {albumList,usaList} from "./listData";
import NewsTitle from "./components/NewsTitle.js";
function News() {
  const _path = ([
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'News',
      href: '',
    },
  ])
  return (
    <Fragment>
      <Header></Header>
      {/* <Title title='News' bg={Bg}></Title> */}
      <Equipment title={'News'} path={_path} />
      {/* <NewsTitle/> */}
      <main className={styles.main}>
        <CardList
          hasMore={false}
          className={styles.customCard}
          list={albumList.slice(0,4)}
          type={"album"}
          title={"Latest News"}
        ></CardList>
        {/* <CardRowList
          className={styles.customCard}
          list={albumList}
          type={"album"}
          title={"Boxing"}
        ></CardRowList>
        <CardRowList
          className={styles.customCard}
          list={albumList}
          type={"album"}
          title={"Sanda"}
        ></CardRowList> */}
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default News;
