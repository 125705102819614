import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import Equipment from '../components/navigater'
import styles from './index.rem.module.scss'
import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { smart } from '../home/data'
import Card from './Card'
import CardStyle from './card.rem.module.scss'
function Article() {
  const params = useParams()

  const [articleInfo, setArticleInfo] = useState({})

  const [_path, setPath] = useState([])

  const getContent = articleId => {
    const article = smart.filter(item => item.id == articleId)[0]
    setPath([
      {
        title: 'Home',
        href: '/',
      },
      {
        title: article.title,
        href: '',
      },
    ])
    setArticleInfo(article)
  }

  useEffect(() => {
    if (params.articleId) {
      getContent(params.articleId)
    }
  }, [params])

  return (
    <Fragment>
      <Header></Header>
      {articleInfo.isBoxing && (
        <>
          <Card
            className={CardStyle.card}
            list={articleInfo.eventVideos}
            type={'video'}
          ></Card>
          <main className={styles.article}>
            <div className={styles.smartTitle}>
              <p>{articleInfo.title}</p>
              {articleInfo.secTitle && (
                <p className={styles.secTitle}>{articleInfo.secTitle}</p>
              )}
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
            <div style={{ padding: '0 15px' }}>
              <img
                className={styles.img}
                style={{ width: '100%' }}
                src={articleInfo.imgDetail}
                alt=''
              />
            </div>
            <div className={styles.aboutTitle}>About The System</div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.aboutContent,
              }}
            ></div>
            {articleInfo.aboutImgs && articleInfo.aboutImgs.length && (
              <div className={styles.aboutImgs}>
                {articleInfo.aboutImgs.map(item => {
                  return <img src={item.img} alt='' />
                })}
              </div>
            )}
            <div className={styles.aboutTitle}>Event Experiences</div>
            <div className={styles.experience}>
              <img src={articleInfo.expImg} alt=' ' />
            </div>
          </main>
        </>
      )}
      {(articleInfo.isBreaking || articleInfo.isSkb || articleInfo.isEoms) && (
        <>
          <Equipment title={articleInfo.parent} path={_path} />
          <main className={styles.article}>
            <div className={styles.smartTitle}>
              <p>{articleInfo.title}</p>
              {articleInfo.secTitle && (
                <p className={styles.secTitle}>{articleInfo.secTitle}</p>
              )}
            </div>
            <div style={{ padding: '0 15px' }}>
              <img
                className={styles.onlyImg}
                style={{ width: '100%' }}
                src={articleInfo.imgDetail}
                alt=''
              />
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
            
            {
              articleInfo.isEoms && <div className={styles.aboutTitle}>Main features of the EOMS</div>
            }
            {
              !articleInfo.isEoms && <div className={styles.aboutTitle}>Functions</div>
            }
            {
              articleInfo.aboutContent && <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.aboutContent,
              }}
            ></div>
            }
            {/* <div className={styles.aboutTitle}>Functions</div> */}
            {articleInfo.functions && articleInfo.functions.length > 0 && (
              <div className={styles.functions}>
                {articleInfo.functions.map((item, index) => {
                  return (
                    <p className={styles.function} key={index}>
                      {item}
                    </p>
                  )
                })}
              </div>
            )}
            <div className={styles.aboutImg}>
              {articleInfo.aboutImgs.map((item, index) => {
                return <img key={index} alt='' src={item.img} />
              })}
            </div>
            {
              articleInfo.aboutContent2 && <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.aboutContent2,
              }}
            ></div>
            }
            {
              articleInfo.aboutImgs2 && <div className={styles.aboutImg}>
              {articleInfo.aboutImgs2.map((item, index) => {
                return <img key={index} alt='' src={item.img} />
              })}
            </div>
            }
            
            <div className={styles.aboutTitle}>Event Experiences</div>
            <div className={styles.experience}>
              <img src={articleInfo.expImg} alt=' ' />
            </div>
          </main>
        </>
      )}
      {/* {
        articleInfo.isEoms && <>
          <main className={styles.article}>
            <div className={styles.smartTitle}>
              <p>{articleInfo.title}</p>
                {articleInfo.secTitle && <p className={styles.secTitle}>{articleInfo.secTitle}</p>}
            </div>
            <div className={styles.introduce} style={{flexDirection:'column'}}>
              <img
                className={styles.mainImg}
                width={1400}
                height={600}
                src={articleInfo.imgDetail}
                alt=''
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: articleInfo.editor,
                }}
              ></div>
            </div>
            <div className={styles.aboutTitle}>
              Main features of the EOMS
            </div>
            <div
            dangerouslySetInnerHTML={{
              __html: articleInfo.aboutContent,
            }}
            >

            </div>
            {
              articleInfo.functions && articleInfo.functions.length> 0 && <div style={{marginBottom: '32px'}}>
                {
                  articleInfo.functions.map((item, index) => {
                    return <p className={styles.function} key={index}>{ item }</p>
                   
                  })
                }
              </div>
            }
            <div className={styles.aboutImg}>
              {
                articleInfo.aboutImgs.map((item, index) => {
                  return <img key={index} alt="" src={item.img} width={680} height={381} />
                })
            }
            </div>
            <div className={styles.aboutTitle} style={{marginTop: '72px', marginBottom: '55px'}}>
              Event Experiences
            </div>
            <div >
              <img width={1400} height={articleInfo.isSkb ? 245 : 748} src={ articleInfo.expImg } alt=" " />
            </div>
          </main>
        </>
      } */}
      {articleInfo.isNormal && (
        <>
          <Equipment title={articleInfo.parent} path={_path} />
          <main className={styles.article}>
            <div className={styles.smartTitle}>
              <p>{articleInfo.title}</p>
              {articleInfo.secTitle && (
                <p className={styles.secTitle}>{articleInfo.secTitle}</p>
              )}
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: articleInfo.editor,
              }}
            ></div>
            <div style={{ padding: '0 15px' }}>
              <img
                class={styles.onlyImg}
                style={{ width: '100%' }}
                src={articleInfo.imgDetail}
                alt=''
              />
            </div>
          </main>
        </>
      )}

      <Footer></Footer>
    </Fragment>
  )
}

export default Article
