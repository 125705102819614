/*
 * @Description: 旧版本news页面
 * @Author: 翁庆雨 <wqy.mail@foxmail.com>
 * @Date: 2024-06-12 11:08:56
 * @LastEditTime: 2024-06-12 11:09:13
 * @LastEditors: 翁庆雨  <wqy.mail@foxmail.com>
 * @FilePath: /xempowerusa/src/sports-en/news copy/index.js
 */
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import styles from "./index.module.scss";
import { Fragment } from "react";
import Title from "../components/title/title";
import { NavLink } from "react-router-dom";

function News() {
  return (
    <Fragment>
      <Header></Header>
      <Title title='News'></Title>
      <main className={styles.main}>
        <div className={styles.picPart1}>
          <NavLink key={1} to={"/detail"}>
            <p className={styles.bigTitle}>
              2024 U.S. Olympic Team Trials 203+lbs/92+kg CHAMPION Ali Feliz
              with Xempower's Eason Zhang
            </p>
            {/* <p className={styles.midTitle}>2024 U.S. Olympic Team Trials 203+lbs/92+kg CHAMPION Ali Feliz with Xempower's Eason Zhang</p> */}
            <p className={styles.smallTitle}>April 15, 2024</p>
            <div className={styles.fl_spb}>
              <div className={styles.picBox}>
                <img
                  className={styles.picture}
                  src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
                  alt='news'
                />
                <div className={styles.tip}>Boxing</div>
              </div>

              <div className={styles.picBoxR}>
                <img
                  className={styles.picture}
                  src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
                  alt='news'
                />
                <div className={styles.tip}>Marathon</div>
                <p className={styles.bigTitle}>
                  November 2023 Neutral Corner Now Online
                </p>
                <p className={styles.midTitle}>
                  Marathon is the beginning for runners, …
                </p>
                <p className={styles.smallTitle}>Nov 21, 2023</p>
              </div>
            </div>
          </NavLink>
        </div>

        <div className={styles.picPart2}>
          <div className={styles.item}>
            <div className={styles.posr}>
              <img
                className={styles.picture}
                src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
                alt='news'
              />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>
              November 2023 Neutral Corner Now Online …
            </p>
            <p className={styles.midTitle}>Marathon is the beginning for …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>

          <div className={styles.item}>
            <div className={styles.posr}>
              <img
                className={styles.picture}
                src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
                alt='news'
              />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>
              November 2023 Neutral Corner Now Online …
            </p>
            <p className={styles.midTitle}>Marathon is the beginning for …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>

          <div className={styles.item}>
            <div className={styles.posr}>
              <img
                className={styles.picture}
                src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
                alt='news'
              />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>
              November 2023 Neutral Corner Now Online …
            </p>
            <p className={styles.midTitle}>Marathon is the beginning for …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>
        </div>

        <div className={styles.picPart3}>
          <div className={styles.item}>
            <img
              className={styles.picture}
              src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
              alt='news'
            />
            <div className={styles.info}>
              <p className={styles.bigTitle}>
                November 2023 Neutral Corner Now Online November 2023…
              </p>
              <p className={styles.midTitle}>
                Marathon is a sport that is characterized by broad
                participation, great influence, and a long industry chain. It
                plays an increasingly important role in p
              </p>
              <p className={styles.smallTitle}>
                <span className={styles.tip}>Rowing</span> Nov 21, 2023
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <img
              className={styles.picture}
              src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
              alt='news'
            />
            <div className={styles.info}>
              <p className={styles.bigTitle}>
                November 2023 Neutral Corner Now Online November 2023…
              </p>
              <p className={styles.midTitle}>
                Marathon is a sport that is characterized by broad
                participation, great influence, and a long industry chain. It
                plays an increasingly important role in p
              </p>
              <p className={styles.smallTitle}>
                <span className={styles.tip}>Rowing</span> Nov 21, 2023
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <img
              className={styles.picture}
              src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg'
              alt='news'
            />
            <div className={styles.info}>
              <p className={styles.bigTitle}>
                November 2023 Neutral Corner Now Online November 2023…
              </p>
              <p className={styles.midTitle}>
                Marathon is a sport that is characterized by broad
                participation, great influence, and a long industry chain. It
                plays an increasingly important role in p
              </p>
              <p className={styles.smallTitle}>
                <span className={styles.tip}>Rowing</span> Nov 21, 2023
              </p>
            </div>
          </div>
        </div>

        <div className={styles.picPart4}>
          <div className={styles.item}>
            <p className={styles.bigTitle}>
              November 2023 Neutral Corner Now Online November 2023 Neutral…
            </p>
            <p className={styles.midTitle}>
              Marathon is a sport that is characterized by broad participation,
              great influence, and a long industry chain. It plays an
              increasingly important role in promoting further integration of
              national fitness and public health. With professional
              organization, thoughtful event services, organic integration with
              local culture, organic integration with local culture …
            </p>
            <p className={styles.smallTitle}>
              <span className={styles.tip}>Rowing</span> Nov 21, 2023
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.bigTitle}>
              November 2023 Neutral Corner Now Online November 2023 Neutral…
            </p>
            <p className={styles.midTitle}>
              Marathon is a sport that is characterized by broad participation,
              great influence, and a long industry chain. It plays an
              increasingly important role in promoting further integration of
              national fitness and public health. With professional
              organization, thoughtful event services, organic integration with
              local culture, organic integration with local culture …
            </p>
            <p className={styles.smallTitle}>
              <span className={styles.tip}>Rowing</span> Nov 21, 2023
            </p>
          </div>
          <div className={styles.item}>
            <p className={styles.bigTitle}>
              November 2023 Neutral Corner Now Online November 2023 Neutral…
            </p>
            <p className={styles.midTitle}>
              Marathon is a sport that is characterized by broad participation,
              great influence, and a long industry chain. It plays an
              increasingly important role in promoting further integration of
              national fitness and public health. With professional
              organization, thoughtful event services, organic integration with
              local culture, organic integration with local culture …
            </p>
            <p className={styles.smallTitle}>
              <span className={styles.tip}>Rowing</span> Nov 21, 2023
            </p>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default News;
