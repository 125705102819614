import Title from '../home/title'
import Play from '../home/images/btn_play@2x.png'
import Arrow from '../components/header/icon_top_arrow'
import styles from './card.rem.module.scss'
import { NavLink } from 'react-router-dom'
import { useState } from 'react'

function Card(props) {
  const [showAll, setShowAll] = useState(false)

  const changeState = () => setShowAll(!showAll)

  const [list, setList] = useState(props.list)

  const playVideo = (index, id) => {
    list[index].contro = true
    list[index].play = true 
    setList(list.map(item => item))
    setTimeout(() => {
      document.getElementById('video' + id).play()
    }, 50)
    
  }

  const createAlbum = () => {
    return (
      <>
        <ul
          className={`${
             styles.album
          }`}
        >
          {list.map((item, index) => {
            return (
              <li key={index}>
                <NavLink to={item.to}>
                <div
                  className={styles.bg}
                  style={{
                    background: `url('${item.img}') no-repeat`,
                    backgroundSize: '100% auto',
                  }}
                ></div>
                </NavLink>
                
                {/* <h2>{item.title}</h2>
                <NavLink to={item.to}>
                  <p>{item.desc}</p>
                </NavLink> */}
              </li>
            )
          })}
        </ul>
        {/* {!showAll && (
          <div className={styles.loadMore} onClick={changeState}>
            View all
            <Arrow color='#6D4C47' />
          </div>
        )} */}
      </>
    )
  }

  const createVideo = () => {
    return (
      <div className={styles.videoBox}>
        {props.list.map((item, index) => (
          <div key={index}>
            {/* {!item.contro && !item.play && (
              <img
                className={styles.play}
                // style={{opacity: 0}}
                src={Play}
                alt='play'
                onClick={() => playVideo(index, item.id)}
              />
            )} */}
            {/* {!item.contro && !item.play && (
              <img className={styles.poster} src={item.poster} alt='poster' />
            )} */}
            {/* {
             item.play && <video
              id={'video' + item.id}
              controls
              role='img'
                src={item.src}
                poster={item.poster}
              ></video>
              
            } */}
            <video
              id={'video' + item.id}
              controls
              role='img'
              src={item.src}
              poster={item.poster}
            ></video>
            
          </div>
        ))}
      </div>
    )
  }

  const _render = () => {
    switch (props.type) {
      case 'album':
        return createAlbum()
      case 'video':
        return createVideo()
      default:
        return <></>
    }
  }

  return (
    <div className={`${styles.card} ${props.className}`}>
      { props.title ? <Title title={props.title} /> : ''}
      {_render()}
    </div>
  )
}

export default Card
