import Header from '../components/header/header'
import Footer from '../components/footer/footer'
import styles from './index.rem.module.scss';
import { Fragment } from 'react';
import Title from '../components/title/title';
import { NavLink } from "react-router-dom";
import Bg from './images/img_news_banner.jpg'


function News() {

  return (
    <Fragment>
      <Header></Header>
      <Title title='News' bg={Bg}></Title>
      <main className={styles.main}>
        <div className={styles.picPart1}>
          <NavLink key={1} to={'/detail'}>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online</p>
            <p className={styles.midTitle}>Marathon is the beginning for runners, while cross-country running …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
            <div className={styles.fl_spb}>
              <div className={styles.picBox}>
                <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
                <div className={styles.tip}>Marathon</div>
              </div>
            </div>
          </NavLink>
        </div>

        <div className={styles.picPart5}>
          <div>
            <div className={styles.tipBox}>
              <img src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online</p>
            <p className={styles.midTitle}>Marathon is the beginning for runners, …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>
          <div>
            <div className={styles.tipBox}>
              <img src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online</p>
            <p className={styles.midTitle}>Marathon is the beginning for runners, …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>
        </div>

        {/* <div className={styles.picPart2}>
          <div className={styles.item}>
            <div className={styles.posr}>
              <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online …</p>
            <p className={styles.midTitle}>Marathon is the beginning for …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>

          <div className={styles.item}>
            <div className={styles.posr}>
              <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online …</p>
            <p className={styles.midTitle}>Marathon is the beginning for …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>

          <div className={styles.item}>
            <div className={styles.posr}>
              <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
              <div className={styles.tip}>Marathon</div>
            </div>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online …</p>
            <p className={styles.midTitle}>Marathon is the beginning for …</p>
            <p className={styles.smallTitle}>Nov 21, 2023</p>
          </div>
        </div> */}

        <div className={styles.picPart3}>
          <div className={styles.item}>
            <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
            <div className={styles.info}>
              <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online November 2023…</p>
              <p className={styles.midTitle}>Marathon is a sport that is characterized by broad participation, great influence, and a long industry chain. It plays an increasingly important role in p</p>
              <p className={styles.smallTitle}><span className={styles.tip}>Rowing</span> Nov 21, 2023</p>
            </div>
          </div>
          <div className={styles.item}>
            <img className={styles.picture} src='https://multi-xm.oss-cn-hangzhou.aliyuncs.com/saas/static/img_quipment_1%402x.jpg' alt='news' />
            <div className={styles.info}>
              <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online November 2023…</p>
              <p className={styles.midTitle}>Marathon is a sport that is characterized by broad participation, great influence, and a long industry chain. It plays an increasingly important role in p</p>
              <p className={styles.smallTitle}><span className={styles.tip}>Rowing</span> Nov 21, 2023</p>
            </div>
          </div>
        </div>

        <div className={styles.picPart4}>
          <div className={styles.item}>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online November 2023 Neutral…</p>
            <p className={styles.midTitle}>Marathon is a sport that is characterized by broad participation, great influence, and a long industry chain. It plays an increasingly important role in promoting further integration of national fitness and public health. With professional organization, thoughtful event services, organic integration with local culture, organic integration with local culture …</p>
            <p className={styles.smallTitle}><span className={styles.tip}>Rowing</span> Nov 21, 2023</p>
          </div>
          <div className={styles.item}>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online November 2023 Neutral…</p>
            <p className={styles.midTitle}>Marathon is a sport that is characterized by broad participation, great influence, and a long industry chain. It plays an increasingly important role in promoting further integration of national fitness and public health. With professional organization, thoughtful event services, organic integration with local culture, organic integration with local culture …</p>
            <p className={styles.smallTitle}><span className={styles.tip}>Rowing</span> Nov 21, 2023</p>
          </div>
          <div className={styles.item}>
            <p className={styles.bigTitle}>November 2023 Neutral Corner Now Online November 2023 Neutral…</p>
            <p className={styles.midTitle}>Marathon is a sport that is characterized by broad participation, great influence, and a long industry chain. It plays an increasingly important role in promoting further integration of national fitness and public health. With professional organization, thoughtful event services, organic integration with local culture, organic integration with local culture …</p>
            <p className={styles.smallTitle}><span className={styles.tip}>Rowing</span> Nov 21, 2023</p>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </Fragment>
  );
}

export default News;