import styles from './header.rem.module.scss';
import Arrow from './icon_top_arrow';
import { NavLink, useLocation } from "react-router-dom";
import Search from './images/header-search.png';
import Logo from './images/logo.png';
import logoW from './images/logow.png';
import More from './images/header-more.png';
import Close from './images/header-close.png';
import { useState } from 'react';
import { album, smart } from '../../home/data';

function Header() {

  const { pathname } = useLocation();

  const [active, setActive] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  

  const sportsProgrammesSecList = album.map( item => {
    return Object.assign(item, {
      to: '/article/' + item.id
    })
  })

  const smartSecList = smart.map( item => {
    return Object.assign(item, {
      to: '/smartarticle/' + item.id
    })
  })

  const equipmentList = [
    {
      id: 2,
      parent: 'equipment',
      title: 'AI-Powered Punching Bag',
      to: '/equipment-boxing'

    },
    {
      id: 1,
      parent: 'equipment',
      title: 'Skateboarding',
      to: '/equipment'

    },
    
  ]

  const [menu, setMenu] = useState([{
    title: 'Home',
    id: 0,
    to: '/',
    activeKey: '/'
  },{
    title: 'Event Operation',
    activeKey: '/sportsprogrammes',
    width: 230,
    id: 1,
    isOpen: false,
    child: sportsProgrammesSecList
  },{
    title: 'Event Systems',
    activeKey: '/sportsprogrammes',
    width: 360,
    id: 2,
    isOpen: false,
    child: smartSecList
    },
    {
      title: 'Equipment Solutions',
      // to: '/equipment',
      id: 3,
      child: equipmentList
    },
    {
    title: 'News',
    to: '/newslist',
    id: 4
    }
    ,
    {
    title: 'About Us',
    to: '/about',
    id: 5
  }]);

  const changeNavState = flag => {
    setIsOpen(flag);
    setMenu(menu.map( itemd => {
      itemd.isOpen = false
      return itemd;
    }))
  }

  const jumpTo = href => {
    window.location.href = href;
  }

  const createChild = (item) => {

    return item.child.map(( item, index) => {
      return (
        <dl key={index}>
          <dd onClick={() => jumpTo(item.to)}>{item.title}</dd>
        </dl>
      )
    })
  }

  const onClickNavigater = (item) => {
    if( item.to ){
      jumpTo(item.to);
    }
    if( item.child ){
      setMenu(menu.map( itemd => {
        if( itemd.id === item.id ){
          itemd.isOpen = !itemd.isOpen
        }
        return itemd;
      }))
    }
  }

  return (
    <header className={`${styles.header} ${isOpen ? styles.open : ''}`}>
      <div>
        <img className={styles.logo} src={Logo} alt='logo' />
        <img className={styles.logoW} src={logoW} alt='logo' />
        <div className={styles.action}>
          <img className={styles.actionIcon} src={Search} alt='search' />
          <img className={styles.actionIcon} src={More} alt='more' onClick={() => changeNavState(true)} />
        </div>
        <div className={styles.actionw}>
          <img className={styles.actionIcon} src={Close} alt='close' onClick={() => changeNavState(false)} />
        </div>
      </div>
      <nav>
        <ul>
          {
            menu.map( (item, index) => {
              return (
                <li className={item.isOpen ? styles.show : ''} key={index} onClick={() => onClickNavigater(item)}>
                  <div>
                    {item.title}
                    {item.child && <Arrow color="#fff" />}
                  </div>
                  {
                    item.child && createChild(item)
                  }
                </li>
              )
            })
          }
        </ul>
      </nav>
    </header>
  );
}

export default Header;