import styles from "./Card.module.scss";
import { NavLink } from "react-router-dom";

function Card({ item, index, row }) {
  return (
    <div className={`${styles.card} `}>
      <div key={index} className={styles.swiperItem}>
        <NavLink
          className={`${
            row === 2 ? styles.swiperItemTitleRow     : styles.swiperItemTitle
          }`}
          to={"/news-detail/" + item.id}
        >
          <div
            className={styles.swiperItemImg}
            style={{ backgroundImage: `url('${item.img}')    ` }}
          >
            <p className={`${styles.date} `}> {item.date}</p>
          </div>
        </NavLink>
        <NavLink
          className={`${styles.swiperItemTitle}`}
          to={"/news-detail/" + item.id}
        >
          {item.title}
        </NavLink>
        <NavLink
          className={styles.swiperItemDesc}
          to={"/news-detail/" + item.id}
        >
          <div className={styles.brief}>{item.desc}</div>
        </NavLink>
      </div>
    </div>
  );
}

export default Card;
